@import "../../../../material_base/sass/color-palette";
@import "../../../../material_base/sass/variables";

$container: 1230px;
// layout
$content: 720px !default; //720px
$sidebar: 355px !default;
$gutter: 13px !default;
$drawer: $sidebar !default;
$navbar: 64px !default;
$search: 392px !default;

/* break points */

$ss: 360px !default;
$xs: $sidebar * 2 + $gutter * 3 + 16px !default; //568px (including scrollbar width)
$sm: 768px !default;
$md: 1136px; //992px
$lg: $content + $sidebar + $gutter * 3 !default; //1256px
$xl: $content + $sidebar * 2 + $gutter * 4 !default; //1520px

@custom-media --big-viewport (max-width: 1136px);
@custom-media --middle-viewport (max-width: 768px);
@custom-media --small-viewport (max-width: 480px);

/*Fonts*/
$font-family: "Roboto", sans-serif;
$h1FontSize: 40px;
$font-size: 18px;
$font-weight: 400;

/*Colors*/
$blueCustom: #0c5982;
$greyCustom: #e4e4e4;
$blueLightCustom: #2bb1c5;
$mainColor: #434343;
$mainColorHover: #6a6a6a;
$secondColor: #1487c5;
$headColor: $blueCustom;
$headBg: #557323;
$menuBg: #f1ecd7;
$footerBg: #f1ecd7;
$green-custom: #a9be3b;
$articles-border: #2bb1c5;
$articles-background: $blueCustom;
$articlesTeaserBody: #7a7a7a;
$green: #a9be3b;

$btnBlue: #026fc2;
$dottedBorderColor: #7a7a7a;
