article.node-article,
article.node-blog-entry {
  .field-name-field-related-publications {
    .field-label {
      font: normal normal 300 34px/46px Roboto;
      letter-spacing: -0.94px;
      color: $blueCustom;
    }
  }
  .field-name-field-tags {
    .field-label {
      font: normal normal 300 34px/46px Roboto;
      letter-spacing: -0.94px;
      color: $blueCustom;
      margin-bottom: 8px;
    }
    .field-items {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .field-item {
      background: $secondColor;
      color: #fff;
      border: 1px solid transparent;
      font: normal normal normal 12px/12px Roboto;
      transition: all 0.3s ease;
      padding: 9px;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      a {
        color: #fff;
      }
      &:hover {
        color: $secondColor;
        border: 1px solid $secondColor;
        background: #fff;
        a {
          color: $secondColor;
        }
      }
    }
  }
}

.field-name-field-tags {
  margin-bottom: 27px;
}
