.menu {
  display: flex;
  flex-wrap: wrap;
  li {
    a {
      font: normal normal 300 16px/16px Roboto;
      letter-spacing: 0.26px;
      color: #ffffff;
      padding: 0 22px;
      display: inline-block;
      @media (--big-viewport) {
        padding: 0 14px;
      }
    }
  }
}

.region-header {
  .menu {
    li {
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 21px;
        width: 1px;
        background: #ffffff;
      }
      &.last {
        &:after {
          display: none;
        }
      }
    }
  }
}
