.header-wrapper {
  background: $headBg;
}

.menu-wrapper {
  background: $menuBg;
}

.footer-wrapper {
  background: $footerBg;
}

@media only screen and (min-width: $lg) {
  .container {
    width: $md;
  }
  .one-sidebar .container {
    width: $md;
  }
}
