.math-inline {
  display: inline;

  *,
  .MathJax_Display {
    display: inline-block !important;
    width: auto;
    text-align: center;
  }

  .MathJax_Preview,
  script {
    display: none !important;
  }

}

.MathJax_Display {
  overflow-y: hidden;
  overflow-x: auto;
}