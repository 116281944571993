@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInLeft {
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideInTop {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInTop {
  animation-name: slideInTop;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideToTop {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, -100%, 0);
  }
}
.animate__slideToTop {
  animation-name: slideToTop;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}
