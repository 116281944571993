html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: $font-family;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.4;
  font-size: $font-size;
  font-family: $font-family;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  color: $secondColor;
  &:hover {
    text-decoration: underline;
  }
}
.page-title,
h1 {
  color: $headColor;
  font-size: $h1FontSize;
  font-weight: 300;
}
h2,
h3,
h4,
h5,
h6 {
  color: $headColor;
  font-weight: 600;
}

h2 {
  font-size: 24px;
}
h3 {
  font: normal normal normal 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: $blueCustom;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}

h1 + h1,
h1 + h2,
h1 + h3,
h1 + h4,
h1 + h5,
h1 + h6,
.h1 + h1,
.h1 + h2,
.h1 + h3,
.h1 + h4,
.h1 + h5,
.h1 + h6,
h2 + h1,
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
.h2 + h1,
.h2 + h2,
.h2 + h3,
.h2 + h4,
.h2 + h5,
.h2 + h6 {
  margin-top: 0;
}
