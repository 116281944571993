main {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
.bllue {
  color: $mainColor;
}
.col-md-8.green-line {
  height: 4px;
}
.page-node-1461,
.page-node-337 {
  #page-title {
    line-height: 1.1;
    font-size: 30px;
  }
}
#after-main {
  position: relative;
  z-index: 10;
  background-color: #fff;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
.btn {
  display: inline-block;
  text-align: center;
  border: none;
  outline: none;
  text-decoration: none;
}
.btn-yellow {
  color: #fff;
  background: #c89b22;
  height: 50px;
  line-height: 50px;
  padding: 0 27px;
  font-weight: 600;
  border-radius: 24px;
  text-transform: uppercase;
  .icon {
    margin-right: 15px;
  }
}

#header {
  background-color: #cfcfcf;
}

#page {
  display: block;
  position: relative;
  overflow-x: hidden;
}

#main {
  .block {
    margin: 15px 0;
    &#block-views-articles-block-1 {
      margin-bottom: 17px;
      border-top: 1px dotted $dottedBorderColor;
      padding-top: 38px;
      @media (--middle-viewport) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
    }
    &#block-views-articles-block-articles {
      margin-top: 0;
      margin-bottom: 56px;
      @media (--middle-viewport) {
        margin-bottom: 20px;
      }
    }
    &#block-views-welcome-text-block {
      margin-top: 0;
      margin-bottom: 35px;
      @media (--middle-viewport) {
        margin-bottom: 20px;
      }
    }
    &#block-views-resume-block {
      margin-bottom: 40px;
      @media (--middle-viewport) {
        margin-bottom: 20px;
      }
    }
    &#block-webform-client-block-1474 {
      margin-bottom: 57px;
      @media (--middle-viewport) {
        margin-bottom: 20px;
      }
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

strong,
b {
  font-weight: 700;
}

#main .block {
  margin: 12px 0;
}

.one-sidebar #sidebar-first {
  order: 0;
}

.green-line {
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background: $green;
  }
}
body {
  overflow-x: hidden;
}
p {
  margin-bottom: 25px;
}
h2 {
  font: normal normal 300 34px/46px Roboto;
  font-family: $font-family;
  letter-spacing: 1px;
  color: $blueCustom;
}

.pager .pager-item.item-previous > a span,
.pager .pager-item.item-first > a span,
.pager .pager-item.item-next > a span,
.pager .pager-item.item-next > span span,
.pager .pager-item.item-last > a span,
.pager .pager-item.item-last > span span {
  display: inline;
}

.pager .pager-item.item-next > a::before,
.pager .pager-item.item-next > span::before,
.pager .pager-item.item-last > a::before,
.pager .pager-item.item-last > span::before,
.pager .pager-item.item-first > a::before,
.pager .pager-item.item-first > span::before,
.pager .pager-item.item-previous > a::before,
.pager .pager-item.item-previous > span::before {
  display: none;
}

.pager-item.item-ellipsis {
  display: none;
}

.pager .pager-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 39px 0;
}
.pager .pager-item {
  &:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 20px;
    background: #dddddd;
  }
}

.pager .pager-item > a,
.pager .pager-item > span {
  margin: 0;
  min-width: inherit;
}

.pager .pager-item > a,
.pager .pager-item > span {
  height: 20px;
  padding: 0px 16px;
  vertical-align: top;
  font: normal normal 300 18px/26px Roboto;
  line-height: 20px;
}

.pager .pager-item.item-first > a,
.pager .pager-item.item-previous > a,
.pager .pager-item.item-next > a,
.pager .pager-item.item-last > a {
  padding: 0 16px;
}

.pager .pager-item.item-current > span {
  font: normal normal bold 18px/26px Roboto;
  color: #1b252e;
  line-height: 20px;
}

.contactsData {
  padding-top: 12px;
  .svg-wrapper {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
  }
  svg {
    fill: $green;
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 6px;
    @media (--middle-viewport) {
      flex-direction: column;
    }
    &-face {
      .contactsData__border {
        width: 150px;
        @media (--big-viewport) {
          display: none;
        }
      }
    }
    &-email {
      .contactsData__border {
        width: 216px;
        @media (--big-viewport) {
          display: none;
        }
      }
    }
  }
  &__label {
    font: normal normal 300 34px/46px Roboto;
    color: $blueCustom;
    line-height: 1;
  }
  &__info {
    font: normal normal 300 18px/24px Roboto;
    letter-spacing: 0px;
    color: #1178af;
    margin-left: 14px;
    @media (--middle-viewport) {
      margin-left: 0;
    }
  }
  &__border {
    border: 2px dashed #9d9d9d;
    height: 2px;
    margin-left: 15px;
    width: 130px;
    @media (--big-viewport) {
      display: none;
    }
  }
}

.svg-wrapper::before,
.svg-wrapper::after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 0;
}
.svg-wrapper::before {
  left: 5px;
  top: 16px;
  transform: rotate(34deg);
}
.svg-wrapper::after {
  right: -3px;
  top: 16px;
  transform: rotate(-34deg);
}

.registerLink {
  text-align: center;
  &__label {
    font: normal normal 300 18px/24px Roboto;
    color: $blueCustom;
  }
}

.form-item input[type="text"].error,
.form-item input[type="email"].error,
.form-item input[type="password"].error,
.form-item input[type="search"].error,
.form-item input[type="tel"].error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #ff0000;
  padding: 5px;
}

.termsWrapper {
  font: normal normal 300 14px/18px Roboto;
  max-width: 376px;
  color: $blueCustom;
  margin: auto;
  text-align: center;
  a {
    color: #1487c5;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.card-form .form-item-uloginTitle {
  font: normal normal normal 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: $blueCustom;
  text-align: center;
  margin-bottom: 13px;
}
.card-form .form-item-description {
  font: normal normal 300 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: $blueCustom;
  padding-top: 14px;
  text-align: center;
  border-top: 1px dotted $blueCustom;
}

.card-form .ulogin-buttons-container {
  display: inline-flex !important;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 21px;
}

.g-recaptcha {
  & > div {
    max-width: 20px auto;
  }
}

.tabs.primary {
  position: fixed;
  left: 0;
  top: 350px;
  width: 150px;
  flex-wrap: wrap;
  border: 1px dotted #cfcfcf;
  background-color: #cfcfcf;
  padding: 5px;
  border-radius: 5px;
}

.tabs ul li a,
ul.tabs li a {
  display: inline-block;
  line-height: inherit;
  padding: 5px;
  border-bottom: none;
  border-bottom-color: transparent;
  font-size: 14px;
  font-weight: 500;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
  &:hover {
    color: $blueCustom;
  }
}

p {
  color: #7a7a7a;
}

table {
  background: transparent;

  td {
    border-top: 1px dotted #3e3e3e;
    border-right: 1px solid #3e3e3e;
    padding: 21px 10px;
    text-align: center;
    &:first-child {
      text-align: left;
      padding-left: 0;
      width: 278px;
    }
    &:last-child {
      border-right: none;
      text-align: right;
      padding-right: 0;
    }
  }
  tr {
    &:first-child {
      td {
        border-top: 1px solid #3e3e3e;
      }
    }
    &:last-child {
      td {
        border-bottom: 1px solid #3e3e3e;
      }
    }
  }
}

.row__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--middle-viewport) {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.not-front {
  #block-system-main-menu {
    .menu {
      li {
        a {
          font: normal normal 300 16px/16px Roboto;
          letter-spacing: 0.26px;
          color: $blueCustom;
          &:hover {
            color: $blueCustom;
            &:before {
              color: $blueCustom;
            }
          }
        }
      }
    }
  }
}

#navbar {
  z-index: 1;
}
.front {
  #burger_toggle {
    svg {
      @media (--middle-viewport) {
        fill: #c6d84e;
      }
    }
  }
}
#burger_toggle {
  position: absolute;
  z-index: 10;
  left: 33px;
  top: 16px;
  cursor: pointer;
  width: 15px;
  height: 12px;
  svg {
    width: 100%;
    height: 100%;
    fill: #879a87;
  }
}

body {
  overflow-x: hidden;
}

.view-cat {
  .views-row {
    margin-bottom: 10px;
  }
}
.not-front {
  #block-search-form .search-form-trigger svg {
    margin-left: 6px;
    margin-top: 6px;
  }
}
.page-node-916,
.page-node-1443,
.page-node-1365,
.page-user {
  #page-title {
    font-weight: 700;
    line-height: 1.1;
  }
}
.node-type-resume {
  #page-title {
    b {
      display: block;
      &:first-child {
        font-weight: 700;
        line-height: 1.1;
      }
    }
  }
}
#block-block-15,
#block-block-16,
#block-block-17,
#block-block-18,
#block-block-19 {
  font: normal normal bold 14px/24px Roboto;
  color: #014f80;
  max-width: 250px;
  padding-top: 5px;
  max-width: 250px;
  p {
    font: normal normal bold 14px/24px Roboto;
    color: #014f80;
  }
  .soc-links-wrapper {
    .mailto__link {
      display: block;
      margin-left: 0;
      padding-top: 11px;
    }
  }
}
.page-node {
  .sidebar {
    padding-left: 0;
  }
  .page-title,
  h1 {
    font-weight: 300;
  }
}
.sidebar__custom_style {
  &.not-front {
    #block-search-form .search-form-trigger svg {
      margin-left: 10px;
      margin-top: 0;
    }
    #sidebar-first {
      position: static;
      transform: inherit;
      background: transparent;
      height: 560px;
    }
    .region-sidebar-first {
      padding-left: 0;
      padding-top: 9px;
    }
    @media (min-width: 768px) {
      #block-menu-menu-menu-in-sidebar,
      #block-custom-solut-logo-svg-menu {
        display: none;
      }
    }

    #block-menu-menu-blog {
      border-left-color: $green;
      & > h2 {
        font: normal normal bold 24px/16px Roboto;
        letter-spacing: 0.38px;
        color: $mainColor;
        margin-bottom: 0px;
        a {
          color: $mainColor;
          &:hover {
            color: $mainColor;
            text-decoration: underline;
          }
        }
      }
      .menu {
        li {
          a {
            font: normal normal medium 18px/21px Roboto;
            letter-spacing: 0.34px;
            color: $blueCustom;
            padding: 3px 19px;
            display: inline-block;
            &:hover,
            &.active {
              color: #ffffff;
              background: $green;
              &:before {
                background: transparent;
              }
              &:after {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
}

.mailto__link {
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #2bb1c5;
  margin-left: 20px;
}

.breadcrumb {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: -0.5px;
  color: $mainColor;
  a,
  i {
    float: left;
    line-height: 13px;
  }
  i {
    font-size: 13px;
    margin: 0 3px;
  }
  .fa-arrow-right {
    font-size: 8px;
    margin: 0 3px;
  }
  .fa-level-down-alt {
    font-size: 8px;
    padding-top: 3px;
    &:last-child {
      transform: translateY(4px);
    }
  }
  .fa-level-down-alt + a {
    clear: left;
    padding-left: 29px;
    padding-top: 3px;
  }
  .fa-home {
    font-size: 11px;
  }
}

.breadcrumb {
  margin: 12px 0 12px;
}

.not-front.page-node {
  #main {
    & > .row {
      margin: 0;
    }
  }
}

.nodeFull.nodeFull__resume {
  & > .row {
    @media (--middle-viewport) {
      margin: 0;
    }
  }
}

.page-taxonomy-term {
  .nodeTeaser__blog {
    border: 1px solid #2bb1c5;
    margin-bottom: 20px;
    border-radius: 3px;
  }
  &.sidebar__custom_style {
    .nodeTeaser__blog {
      border: none;
      margin-bottom: 0px;
      border-radius: 0px;
    }
  }
}

.page-blog {
  #page-title {
    display: none;
  }
}

@media (min-width: 1185px) {
  .not-front {
    .region-navbar {
      height: 25px;
      position: relative;
    }
    #block-block-2 {
      position: absolute;
      right: 0;
    }
    h1 {
      max-width: 350px;
    }
    .region-header {
      height: 79px;
    }
    div#block-custom-solut-logo-svg {
      z-index: 3;
    }
    footer#footer {
      background-color: #fafafa;
      z-index: 2;
      position: relative;
    }
  }
}
.taxonomy__page__tag {
  .nodeTeaser {
    padding-top: 19px;
    .nodeTeaser__author-info {
      padding-left: 20px;
    }
    .nodeTeaser__author-name {
      transform: translateY(-2px);
      margin-bottom: 3px;
    }
    .nodeTeaser__created {
      transform: translateY(-9px);
      .iconSvg-time {
        transform: translate(-3px, 1px);
      }
      span {
        margin-left: 3px;
      }
    }
  }
}
.view-blog,
.view-cat {
  &.view-display-id-page {
    .nodeTeaser__title {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .nodeTeaser {
      padding-top: 19px;
      .nodeTeaser__author-info {
        padding-left: 20px;
      }
      .nodeTeaser__author-name {
        transform: translateY(-2px);
        margin-bottom: 0px;
      }
      .nodeTeaser__created {
        transform: translateY(-7px);
        .iconSvg-time {
          width: 11px;
          height: 11px;
        }
        span {
          margin-left: 3px;
        }
      }
    }
    .views-row-1 {
      .nodeTeaser {
        padding-bottom: 30px;
        padding-top: 26px;
        .nodeTeaser__author-info {
          padding-left: 19px;
          transform: translateY(-2px);
        }
        .nodeTeaser__author-image {
          margin-left: 0;
        }
        .nodeTeaser__author {
          margin-bottom: 24px;
        }
        .nodeTeaser__author-name {
          font-size: 13px;
          transform: translateY(-1px);
          margin-bottom: 4px;
        }
        .nodeTeaser__created span {
          margin-left: 4px;
        }
        .nodeTeaser__title {
          font: normal normal normal 35px/40px Roboto;
          margin-bottom: 41px;
        }
      }
    }
  }

  .nodeTeaser {
    .nodeTeaser__readMore {
      display: none;
    }
  }
  &.view-display-id-block_2 {
    .nodeTeaser-long .nodeTeaser__author-name {
      @media (min-width: 768px) {
        transform: translate(0px, -4px);
      }
    }
    .nodeTeaser__created {
      @media (min-width: 768px) {
        transform: translate(0px, -10px);
      }
    }
    .nodeTeaser-long .nodeTeaser__author {
      margin-bottom: 20px;
    }
    .nodeTeaser-long .nodeTeaser__title {
      font: normal normal normal 35px/41px Roboto;
      text-decoration: underline;
      @media (--middle-viewport) {
        font: normal normal normal 20px/30px Roboto;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .nodeTeaser-long .nodeTeaser__title {
      margin-bottom: 37px;
    }
  }
}

.sidebar__custom_style {
  #main {
    & > .row {
      width: 100%;
    }
    .region-content {
      padding-left: 8px;
      @media (--middle-viewport) {
        padding-left: 0;
      }
    }
  }
}

.i18n-ru {
  @media (min-width: 768px) {
    #block-custom-solut-logo-svg .logo-site-name {
      font-size: 25px;
      line-height: 26px;
    }
    .region-footer-content {
      padding-left: 114px;
    }
  }
}
#main {
  @media (min-width: 768px) {
    min-height: calc(100vh - 296px);
  }
}
.page-node-1459 {
  @media (min-width: 768px) {
    .green-line {
      padding-left: 16px;
    }
  }
}
@media (max-width: 768px) {
  .front__scroll,
  .paragraphs-item-2-columns-lists
    .field-name-field-text-left-col
    ol
    li::after {
    display: none;
  }
}

.math-inline .MathJax_Display {
  margin: 0;
}

.page-search {
  .green-line {
    padding-left: 4px;
  }
  input.form-submit {
    margin-left: 0;
  }
  .search-results {
    .search-result {
      border: 1px solid #2bb1c5;
      border-radius: 3px;
      padding: 21px 30px 0px;
      h3 {
        a {
          color: $blueCustom;
          font: normal normal normal 18px/26px Roboto;
        }
      }
    }

    .search-snippet-info {
      font: normal normal normal 14px/24px Roboto;
      padding-left: 0;
    }
    .search-info {
      display: none;
    }
  }
}

.taxonomy__page__tag {
  .green-line {
    padding-left: 4px;
  }
  #block-system-main {
    .nodeTeaser {
      border: 1px solid #2bb1c5;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 21px 30px 20px;
    }
  }
}

.page-user-register {
  .form-item-name,
  .form-item-mail {
    .description {
      display: none;
    }
  }
  .form-item-pass-pass2 {
    display: none;
  }
  .form-item-pass {
    .description {
      display: none;
    }
  }
}

#user-register-form .form-item input::-webkit-input-placeholder {
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50;
}
#user-register-form .form-item input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50;
}
#user-register-form .form-item input::-moz-placeholder {
  /* Firefox 19+ */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50;
}
#user-register-form .form-item input:-ms-input-placeholder {
  /* IE 10+ */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50;
}
#user-register-form .form-item input:-moz-placeholder {
  /* Firefox 18- */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50;
}

#user-register-form {
  input:not(input[type="submit"]) {
    padding-left: 13px;
  }
  .password-strength {
    margin-top: 0;
    display: none;
  }
  .form-item label {
    font: normal normal 300 12px/9px Roboto;
    letter-spacing: -0.27px;
    color: #083c50;
  }
  .form-item.form-type-password label {
    top: 20px;
  }
}

.view-table-of-contents {
  .views-field-field-ac-lname {
    svg {
      height: 12px;
      width: 12px;
      fill: $green;
      margin-right: 7px;
    }
  }
}

.region-sidebar-first {
  #block-block-6 {
    svg {
      fill: #ffffff;
    }
    .mailto__link {
      display: none;
    }
  }
}

.searchForm__opened {
  .logo-site-slogan {
    opacity: 0;
  }
}

#footer .block-simplenews {
  label {
    display: none;
  }
}

#user-register-form .form-item.form-type-password label {
  display: none;
}

.form-item input[type="text"]:focus,
.form-item input[type="text"].error:focus,
.form-item input[type="email"]:focus,
.form-item input[type="email"].error:focus,
.form-item input[type="password"]:focus,
.form-item input[type="password"].error:focus,
.form-item input[type="search"]:focus,
.form-item input[type="search"].error:focus,
.form-item input[type="tel"]:focus,
.form-item input[type="tel"].error:focus,
.form-item textarea:focus,
.form-item textarea.error:focus,
.form-item select:focus,
.form-item select.error:focus {
  border: none;
  outline: 2px solid #00b0ff;
}

.page-node-1483 {
  #messages {
    display: none;
  }
}

.paragraphs-item-text-with-indent {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    display: inline-block;
    background-image: url(../images/quotes.png);
    width: 21px;
    height: 15px;
    left: 0;
    top: 30px;
  }
}

.not-front {
  #block-custom-solut-logo-svg-menu {
    @media (max-width: 768px) {
      margin-top: 50px;
    }
  }
}
