.comment {
  background: #f7fafc 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  padding: 29px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 200px;
  margin-bottom: 20px;
  @media (--middle-viewport) {
    height: auto;
    padding: 15px;
    flex-direction: column;
  }
  .links {
    display: flex;
    li {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__left {
    width: 30px;
    @media (--middle-viewport) {
      width: 100%;
    }
  }
  &__right {
    width: calc(100% - 30px);
    padding-left: 22px;
    @media (--middle-viewport) {
      width: 100%;
      padding-left: 0;
    }
  }
  &__fullName {
    font: normal normal bold 14px/24px Roboto;
    color: $secondColor;
    padding-top: 3px;
    margin-bottom: 20px;
  }
  &__content {
    font: normal normal normal 14px/24px Roboto;
    color: #6b6c7e;
    padding-right: 85px;
    @media (--middle-viewport) {
      padding-right: 0;
    }
  }
}

#comments {
  margin-top: 26px;
  border-bottom: 1px dashed #707070;
  margin-bottom: 20px;
  .title {
    margin-bottom: 16px;
  }
}

.node__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .links {
    font: normal normal 300 18px/24px Roboto;
    color: #1178af;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .view__count {
    font: normal normal normal 14px/21px Roboto;
    letter-spacing: 0px;
    color: #8d8d8d;
  }
}

.filter-help {
  float: none;
  padding: 0;
  a {
    padding-left: 0;
  }
}
