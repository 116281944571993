#block-menu-menu-blog {
  border-left: 4px solid $green-custom;
  margin-bottom: 50px;
  & > h2 {
    letter-spacing: 0.38px;
    color: #ffffff;
    text-transform: uppercase;
    padding-left: 19px;
    margin-bottom: 2px;
    font-weight: bold;
    &:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f3be";
      font-size: 10px;
      margin-left: 4px;
    }
  }
  .menu {
    li {
      padding: 12px 0px;
      &.last {
        padding-bottom: 1px;
      }
      a {
        text-decoration: none;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.34px;
        color: #ffffff;
        padding: 0 19px;
        position: relative;
        &:after {
          content: "\f061";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 10px;
          position: absolute;
          top: 50%;
          left: -23px;
          transform: translateY(-50%);
          color: transparent;
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 11px;
          height: 17px;
          background: transparent;
          transition: background ease 0.3s;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &:hover,
        &.active {
          text-decoration: underline;
          color: $green-custom;
          background: none;
          &:before {
            background: $green-custom;
          }
          &:after {
            color: $green-custom;
          }
        }
      }
    }
  }
}

#sidebar-first {
  background: $green;
  height: 100vh;
  position: absolute;
  width: 352px;
  z-index: 9;
  min-height: 751px;
  transform: translate3d(-100%, 0, 0);
  left: 0;
  @media (--middle-viewport) {
    width: 100%;
  }
}
.region-sidebar-first {
  padding: 44px 38px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#block-menu-menu-menu-in-sidebar {
  padding-left: 23px;
  border-left: 2px solid #fff;
  .menu {
    li {
      margin-bottom: 9px;
      a {
      }
    }
  }
}
#block-menu-menu-blog {
  & > h2 {
    font: normal normal bold 24px/16px Roboto;
    letter-spacing: 0.38px;
    color: #ffffff;
    font-weight: bold;
  }
  .menu {
    li {
      width: 100%;
      display: block;
      padding: 12px 0px 13px;
      a {
        color: #fff;
        &:hover {
          color: $green;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .one-sidebar #sidebar-first {
    order: 1;
    flex: 0 0 352px;
    width: 352px;
    margin-right: 0px;
  }
}

.front {
  @media (max-width: 768px) {
    #sidebar-first {
      top: 0;
      width: 100%;
    }
    .page-header {
      .region-sidebar-first {
        padding-left: 16px;
        height: 100%;
      }
    }
    .region-navbar {
      justify-content: flex-end;
    }
    #block-locale-language + #block-block-2 {
      margin-left: 20px;
    }
    .i18n-ru #block-custom-solut-logo-svg {
      width: 250px;
    }
    #block-custom-solut-logo-svg {
      width: 250px;
      @media (--small-viewport) {
        width: 260px;
      }
    }
    #block-custom-solut-logo-svg .logo-site-name {
      width: 180px;
      font-size: 17px;
      line-height: 1.3;
    }
    #block-block-6 {
      position: static;
    }
    .page-header-bg {
      background-color: #839581;
      background-image: none;
      display: none;
    }
    .page-header {
      background-color: #839581;
      box-shadow: 0px -3px 5px 5px #839581;
      height: auto;
      padding-bottom: 15px;
    }

    #block-custom-solut-logo-svg-menu {
      margin-left: 0;
      margin-bottom: 20px;
    }
    #burger_toggle {
      left: 24px;
    }
  }
  @media (max-width: 567px) {
    #burger_toggle {
      left: 17px;
    }
  }
}

.not-front.sidebar__custom_style {
  @media (min-width: 768px) {
    #burger_toggle {
      display: none;
    }
  }
  @media (max-width: 768px) {
    #burger_toggle {
      left: 25px;
    }
    #sidebar-first {
      background: #b1c52b;
      height: 100vh;
      position: absolute;
      width: 100%;
      z-index: 9;
      min-height: 751px;
      transform: translate3d(-100%, 0, 0);
      left: 0;
      top: 0;
    }
    .region-sidebar-first {
      padding-left: 16px;
    }
    #main {
      & > .row {
        margin: 0;
      }
    }
    #block-menu-menu-blog {
      border-left: 4px solid #defd35;
      h2 {
        &:after {
          color: #ffffff;
        }
        & > a {
          color: #fff;
          &:hover {
            text-decoration: underline;
            color: #fff;
          }
        }
      }
      .menu {
        li {
          a {
            color: #ffffff;
            &.active,
            &:hover {
              color: #deff00;
              &:before {
                background: #deff00;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 567px) {
    #burger_toggle {
      left: 17px;
    }
  }
}

@media (min-width: 960px) {
  .toolbar {
    #sidebar-first {
      height: calc(100vh - 64px);
    }
    #burger_toggle {
      top: 80px;
    }
  }
}
