.btn {
  &__blue {
    background: transparent;
    border: 1px solid $blueCustom;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 3px;
    text-transform: inherit;
    font-size: 14px;
    color: $btnBlue;
    @include simpleTransition;
    &:hover {
      color: #fff;
      background: $green;
      border-color: $green;
    }
  }
  &__white {
    background: transparent;
    border: 1px solid #fff;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 3px;
    text-transform: inherit;
    font-size: 14px;
    color: #fff;
    @include simpleTransition;
    &:hover {
      color: #fff;
      background: $green;
      border-color: $green;
    }
  }
  &__tr {
    background: transparent;
    border: 1px solid $blueCustom;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 3px;
    text-transform: inherit;
    font-size: 14px;
    color: $blueCustom;
    @include simpleTransition;
    &:hover {
      background-color: $blueCustom;
      color: #fff;
    }
  }
}
