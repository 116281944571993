.front {
  .bllue {
    color: #fff;
  }
  #block-menu-menu-blog > h2 {
    a {
      color: #fff;
      &:hover {
        color: $green;
      }
    }
  }
  .page-header {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 19px;
      width: 100%;
      left: 0;
      bottom: -19px;
      background-color: $green;
    }
    .front__scroll {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 49%;
      transform: translateX(-50%);

      span {
        margin-left: 10px;
        color: #869884;
      }
      .a {
        opacity: 0.7;
      }
      .b,
      .e {
        fill: none;
      }
      .b {
        stroke: #fff;
        stroke-width: 2px;
      }
      .c {
        fill: #fff;
      }
      .d {
        stroke: none;
      }
    }
    .region-sidebar-first {
      height: calc(100vh - 125px);
      position: relative;
      padding-left: 0;
      @media (--middle-viewport) {
        height: auto;
      }
    }
  }
  .region-sidebar-first {
    padding-top: 88px;
  }
  div#block-system-main {
    margin-top: 84px;
    @media (--middle-viewport) {
      margin-top: 20px;
    }
  }
  #block-block-6 {
    .mailto__link {
      display: none;
    }
  }

  @media (min-width: 768px) {
    #block-custom-solut-logo-svg-menu,
    #block-menu-menu-menu-in-sidebar,
    #burger_toggle {
      display: none;
    }
    #sidebar-first {
      position: static;
      transform: translate3d(0, 0, 0);
      height: auto;
      background-color: transparent;
    }
  }
  @media (--middle-viewport) {
  }
}
