.iconSvg {
  display: inline-block;
  &-user {
    fill: $green;
    width: 16px;
    height: 16px;
  }
  &-time {
    fill: $green;
    width: 16px;
    height: 16px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
