.paragraphs-item-resume {
  border-top: 1px dotted #7a7a7a;
  margin-bottom: 30px;
  padding-top: 19px;
  .paragraphs-items-field-resume-item-link {
    padding-left: 185px;
    @media (--middle-viewport) {
      padding-left: 0;
    }
  }
  .field-name-field-resume-title {
    font: normal normal 300 34px/46px Roboto;
    color: $blueCustom;
    width: 505px;
    margin-bottom: 78px;
    @media (--middle-viewport) {
      width: 100%;
      margin-bottom: 20px;
      font: normal normal 300 20px/25px Roboto;
    }
  }
  .field-name-field-resume-item-title {
    font: normal normal normal 40px/50px Roboto;
    color: $green;
    position: relative;
    margin-bottom: 15px;
    &:before {
      content: "";
      display: block;
      width: 200px;
      height: 1px;
      background: $green;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .field-name-field-resume-item-content {
    padding-left: 184px;
    @media (--middle-viewport) {
      padding-left: 0;
    }
  }
}

.paragraphs-item-list {
  font: italic normal 300 18px/34px Roboto;
  color: #3e3e3e;
  .field-item {
    padding-left: 85px;
    position: relative;
    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #3e3e3e;
      left: 0;
      position: absolute;
      top: 14px;
    }
    &:before {
      content: "";
      display: inline-block;
      background-image: url(../images/sprite.png);
      background-position: -44px 0px;
      width: 54px;
      height: 2px;
      vertical-align: middle;
      position: absolute;
      left: 17px;
      top: 19px;
    }
  }
}

.paragraphs-item-img-descr {
  img {
    border-radius: 5px;
  }
}

.field-name-field-paragraphs {
  & > .field-items {
    & > .field-item {
      margin-bottom: 45px;
      @media (--middle-viewport) {
        margin-bottom: 20px;
      }
    }
  }
}

.paragraphs-item-text-with-indent {
  border-top: 1px dashed #707070;
  border-bottom: 1px dashed #707070;
  padding: 30px 0 15px 88px;
  @media (--middle-viewport) {
    padding: 0px;
  }
  p {
    margin-bottom: 14px;
  }
}

.field-name-field-tags {
  .field-label {
    font: normal normal 300 34px/46px Roboto;
    letter-spacing: -0.94px;
    color: $blueCustom;
  }
}

.paragraphs-item-text-2-columns {
  & > .content {
    display: flex;
    flex-wrap: wrap;
    height: 261px;
    @media (--big-viewport) {
      flex-direction: column;
      height: auto;
    }
  }
  .field {
    width: 50%;
    height: 100%;
    position: relative;
    @media (--big-viewport) {
      width: 100%;
    }
  }
  .field-name-field-text-left-col {
    font: normal normal 300 40px/50px Roboto;
    letter-spacing: 0px;
    color: $blueCustom;
    @media (--middle-viewport) {
      font: normal normal 300 20px/30px Roboto;
    }
    &:before {
      content: "";
      display: block;
      width: 84%;
      height: 2px;
      background-color: $green;
      left: 0;
      bottom: 0;
      position: absolute;
    }
    @media (--middle-viewport) {
      margin-bottom: 20px;
      &:before {
        display: none;
      }
    }
    p {
      margin-bottom: 0;
      color: $blueCustom;
    }
  }
  .field-name-field-text-right-col {
    p {
      &:first-child {
        font: normal normal normal 14px/24px Roboto;
        letter-spacing: 0px;
        color: #7a7a7a;
        margin-bottom: 35px;
        @media (--middle-viewport) {
          margin-bottom: 20pxs;
        }
      }
      &:last-child {
        font: italic normal normal 20px/30px Georgia;
        color: $blueCustom;
      }
    }
  }
}

.paragraphs-item-2-columns-lists {
  & > .content {
    display: flex;
    flex-wrap: wrap;
    @media (--middle-viewport) {
      flex-direction: column;
    }
  }
  .paragraphs-items-field-right-col-list,
  .field-name-field-text-left-col {
    width: 50%;
    @media (--middle-viewport) {
      width: 100%;
    }
  }
  .field-name-field-text-left-col {
    padding-right: 77px;
    @media (--middle-viewport) {
      padding-right: 0;
    }
    ol {
      list-style: decimal;
      padding-left: 11px;
      li {
        font: normal normal normal 14px/16px Roboto;
        color: #7a7a7a;
        margin-bottom: 16px;
        padding-left: 13px;
        padding-right: 54px;
        position: relative;
        @media (--middle-viewport) {
          padding-right: 0;
        }
        &:after {
          content: "";
          display: inline-block;
          background-image: url(../images/sprite.png);
          background-position: 0px 0px;
          width: 22px;
          height: 17px;
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
        .line-text {
          text-decoration: line-through;
        }
        &.line-text-wrapper {
          &:after {
            background-position: -22px 0px;
          }
        }
      }
    }
    .field-items {
      box-shadow: 0px 20px 40px #00000033;
      padding: 21px 30px 26px;
      border-radius: 5px;
    }
    h2 {
      font: normal normal bold 22px/30px Roboto;
      color: $blueCustom;
      margin-bottom: 30px;
    }
  }
}

.paragraphs-item-paragraph-with-logo {
  padding-top: 36px;
  padding-left: 92px;
  svg {
    fill: $green;
  }
  @media (max-width: ) {
  }
  @media (--middle-viewport) {
    padding-top: 20px;
    padding-left: 0;
  }
  .group-left {
    width: 28%;
    @media (--middle-viewport) {
      width: 100%;
    }
  }
  .group-right {
    width: 68%;
    padding-left: 18px;
    @media (--middle-viewport) {
      width: 100%;
      padding-left: 0;
    }
  }
  .field-name-field-par-title {
    font: normal normal 300 20px/30px Roboto;
    color: $blueCustom;
    margin-bottom: 9px;
    p {
      font: normal normal 300 20px/30px Roboto;
      color: $blueCustom;
      margin-bottom: 0;
    }
  }
  .paragraphs-item-list {
    .field-item {
      font: normal normal normal 14px/24px Roboto;
      color: #7a7a7a;
      margin-bottom: 3px;
      padding-left: 91px;
      &:after {
        border: 1px solid $blueCustom;
        top: 8px;
      }
      &:before {
        left: 25px;
        top: 13px;
      }
    }
  }
}

.front {
  .paragraphs-item-paragraph-with-logo {
    padding-left: 0;
    padding-top: 49px;
    .group-left {
      width: 32%;
      @media (--big-viewport) {
        width: 100%;
      }
    }
    .group-right {
      padding-left: 13px;
      @media (--big-viewport) {
        width: 100%;
      }
    }
    svg {
      fill: #e4e4e4;
    }
  }
}
