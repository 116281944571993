.not-front {
  #block-locale-language ul.language-switcher-locale-url li {
    a {
      color: $greyCustom;
    }
    &.active a {
      color: $blueCustom;
    }
  }
  #block-menu-menu-blog {
    h2 {
      a {
        color: #fff;
        &:hover {
          color: #deff00;
        }
      }
    }
  }
  #block-custom-solut-logo-svg {
    .logo-site-name {
      color: $blueCustom;
    }
    .logo-site-slogan {
      color: $mainColor;
    }
  }
  .menu {
    li {
      &:after {
        background: $blueCustom;
      }
      a {
        color: $blueCustom;
      }
    }
  }
  #block-block-2 a {
    color: $blueCustom;
    &:before {
      background-color: $blueCustom;
    }
    svg {
      fill: $blueCustom;
    }
  }
  .region-header {
    justify-content: space-between;
  }
  #block-system-main-menu {
    margin-right: 13px;
    .menu {
      justify-content: flex-end;
    }
  }
  .block-title {
    font: normal normal 300 34px/46px Roboto;
    letter-spacing: -0.94px;
    color: $blueCustom;
  }
  #block-block-6 {
    position: static;
  }
  @media (max-width: 1135px) {
    #page-title {
      clear: both;
      font-size: 24px;
    }
  }
  @media (max-width: 1185px) {
    .region-navbar {
      justify-content: flex-end;
    }
    #block-locale-language + #block-block-2 {
      margin-left: 20px;
    }
    .green-line::before {
      left: 0;
    }
    #header-content {
      margin-bottom: 15px;
    }
    #block-custom-solut-logo-svg .logo-site-name {
      width: 180px;
      font-size: 17px;
      line-height: 1.3;
    }
    #burger_toggle {
      left: 17px;
    }
  }
  @media (max-width: 768px) {
    .region-content {
      padding-top: 15px;
    }
    #main {
      padding-top: 15px;
    }
  }
}
