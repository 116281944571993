.nodeTeaser {
  padding: 25px 17px;
  @media (--middle-viewport) {
    padding: 15px;
  }
  &__article {
    .field-name-field-image {
      margin: 0 -30px;
      img {
        display: block;
        width: 100%;
      }
    }
    .iconSvg-time {
      width: 12px;
      height: 12px;
    }
  }
  &__blog {
    padding: 21px 30px 0px;
    .nodeTeaser__author {
      display: flex;
    }
    .nodeTeaser__author-image {
      display: block;
    }
    .nodeTeaser__author-info {
      padding-left: 19px;
    }
    .nodeTeaser__content {
      margin-bottom: 27px;
    }
    .iconSvg-user {
      display: none;
    }
    .iconSvg-time {
      width: 12px;
      height: 12px;
      margin-left: 2px;
    }
    .nodeTeaser__author {
      margin-bottom: 9px;
    }
    .nodeTeaser__author-name span {
      margin-left: 0;
    }
    .field-name-field-image {
      margin: 0 -30px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  &__author {
    margin-bottom: 5px;
    &-image {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    &-name {
      font: normal normal bold 14px/16px Roboto;
      letter-spacing: -0.32px;
      color: $blueCustom;
      display: inline-flex;
      width: 100%;
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
  &__created {
    font: normal normal 300 12px/14px Roboto;
    color: $blueCustom;
    display: inline-flex;
    width: 100%;
    span {
      display: inline-block;
      margin-left: 8px;
    }
  }
  &__title {
    font: normal normal normal 18px/26px Roboto;
    letter-spacing: -0.5px;
    color: $blueCustom;
    margin-bottom: 16px;
    a {
      color: $blueCustom;
    }
  }
  &__content {
    font: normal normal normal 14px/24px Roboto;
    letter-spacing: 0px;
    color: $articlesTeaserBody;
  }
  &__readMore {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0px 21px;
    @media (--middle-viewport) {
      padding: 0px;
      position: static;
      .btn__blue {
        margin: 10px 0;
      }
    }
  }
  &-long {
  }
}

.nodeTeaser-blue {
  background-color: #0c5982;
  padding: 40px 25px 17px;
}

.views-row-1 {
  .nodeTeaser {
    background-color: $blueCustom;
    .field-name-field-image {
      display: none;
    }
    .nodeTeaser__readMore {
      position: static;
    }
    .nodeTeaser__title {
      color: #fff;
      font: normal normal normal 40px/50px Roboto;
      text-align: left;
      padding: 0;
      @media (--middle-viewport) {
        font: normal normal normal 20px/30px Roboto;
      }
      a {
        color: #fff;
        b {
          font-weight: 400;
        }
      }
    }

    .nodeTeaser__content {
      font: italic normal normal 18px/24px Roboto;
      color: #fff;
      text-align: left;
      padding: 0;
      margin-bottom: 0px;
      .tex2jax {
        @media (min-width: 768px) {
          max-height: 76px;
          overflow: hidden;
        }
      }

      p {
        color: #fff;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @media (--middle-viewport) {
        padding: 0;
        text-align: left;
      }
    }
    .nodeTeaser__author {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    .nodeTeaser__title {
      margin-bottom: 23px;
      @media (--middle-viewport) {
        padding: 0;
        text-align: left;
      }
    }
    .nodeTeaser__readMore {
      @media (--middle-viewport) {
        padding: 0;
      }
    }
    .nodeTeaser__author-image {
      display: block;
      margin-left: 9px;
      @media (--middle-viewport) {
        text-align: left;
        margin-left: 0;
      }
    }
    .nodeTeaser__author-info {
      width: 50%;
      padding-left: 21px;
      transform: translateY(-2px);
    }
    .nodeTeaser__created {
      transform: translateY(-7px);
      color: #fff;
    }
    .iconSvg-time {
      width: 12px;
      height: 12px;
    }
    .nodeTeaser__author-name {
      color: #fff;
      span {
        margin-left: 0;
      }
      a {
        color: #fff;
      }
      .iconSvg-user {
        display: none;
      }
    }
    &.nodeTeaser__blogLong {
      padding-top: 26px;
      padding-left: 30px;
      .nodeTeaser__author-image {
        width: 40px;
      }
      .nodeTeaser__title {
        padding: 0;
        text-align: left;
      }
      .nodeTeaser__content {
        padding: 0;
        text-align: left;
      }
      .nodeTeaser__author-image {
        display: block;
      }
    }
  }
}

.front {
  .view-display-id-block_articles {
    .views-row-1 {
      .nodeTeaser {
        .nodeTeaser__author-name {
          color: $blueCustom;
        }
      }
    }
  }
  .views-row-1 .nodeTeaser.nodeTeaser__blogLong {
    padding-top: 40px;
    padding-bottom: 25px;
    border-radius: 5px;
  }
  .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__title {
    padding: 0px 59px;
    text-align: center;
    margin-bottom: 73px;
    @media (--middle-viewport) {
      padding: 0;
      margin-bottom: 10px;
    }
  }
  .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__content {
    padding: 0 222px;
    text-align: center;
    margin-bottom: 21px;
    @media (--middle-viewport) {
      padding: 0;
    }
  }
  .view-articles.view-display-id-block_articles {
    .views-row-1 .nodeTeaser {
      background: #fff;
    }
    .views-row-1 .nodeTeaser .nodeTeaser__author-name a {
      color: #0c5982;
    }
    .views-row-1 .nodeTeaser .nodeTeaser__created {
      color: #0c5982;
      transform: translateY(0px);
    }
    .views-row-1 .nodeTeaser .nodeTeaser__author-name .iconSvg-user {
      display: block;
    }
    .views-row-1 .nodeTeaser .nodeTeaser__title {
      font: normal normal normal 18px/26px Roboto;
    }
    .views-row-1 .nodeTeaser .nodeTeaser__title a {
      color: #0c5982;
      font: normal normal normal 18px/26px Roboto;
    }
    .views-row-1 .nodeTeaser .nodeTeaser__content p {
      font: normal normal normal 14px/24px Roboto;
      letter-spacing: 0px;
      color: #7a7a7a;
    }

    .views-row-1 .nodeTeaser .nodeTeaser__readMore {
      position: absolute;
      @media (--middle-viewport) {
        position: static;
      }
    }
  }
}

body #block-views-articles-block-articles .nodeTeaser__blog {
  @media (--middle-viewport) {
    height: auto;
  }
}
