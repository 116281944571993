/* Оглавление в статье блога */
#block-views-table-of-contents-block {
  max-width: 300px;
}
#block-views-table-of-contents-block h2 {
  text-align: left;
  font: normal normal 300 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982;
  opacity: 1;
  margin-bottom: 12px;
  margin-top: 30px;
}

#block-views-table-of-contents-block a {
  text-align: left;
  text-decoration: none;
  font: normal normal 300 14px/26px Roboto;
  letter-spacing: -0.39px;
  color: #0c5982;
  opacity: 1;
  &:hover {
    text-decoration: underline;
  }
}
#block-views-table-of-contents-block a:before {
  content: "";
  background: url(../../src/svg/arrow-right.svg) no-repeat;
  background-size: cover;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 5px;
}

/* Автор в сайдбаре на странице блога */
#block-views-table-of-contents-block-1 .views-field {
  display: inline-block;
  vertical-align: middle;
}
#block-views-table-of-contents-block-1 .views-field-picture {
  margin-right: 10px;
}
#block-views-table-of-contents-block-1 .name {
  text-align: left;
  font: normal normal bold 14px/16px Roboto;
  letter-spacing: -0.32px;
  color: #0c5982;
  opacity: 1;
  margin-bottom: 4px;
}
.welcomeTeaser__author a,
.nodeTeaser__author-name a {
  color: #0c5982;
}

#block-views-table-of-contents-block-1 .date {
  text-align: left;
  font: normal normal 300 12px/14px Roboto;
  letter-spacing: -0.27px;
  color: #0c5982;
  opacity: 1;
}

/* ссылки в крошках */
.breadcrumb a {
  text-align: left;
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: $mainColor;
  opacity: 1;
}

/* Статья */
.node-webform .field-name-body,
.node-page .field-name-body,
.node-blog-entry .field-name-body,
.node-article .field-name-body {
  text-align: left;
  font: normal normal 300 18px/24px Roboto;
  letter-spacing: 0px;
  color: #434343;
}

.node-page .field-name-body ul,
.node-blog-entry .field-name-body ul,
.node-article .field-name-body ul {
  margin: 40px 0;
}

.node-page .field-name-body ul > li,
.node-blog-entry .field-name-body ul > li,
.node-article .field-name-body ul > li {
  background: url("../../src/svg/dotts.svg") no-repeat;
  background-size: 54px 2px;
  background-position: 18px 12px;
  padding-left: 86px;
  text-align: left;
  font: italic normal 300 18px/34px Roboto;
  letter-spacing: -0.15px;
  color: #3e3e3e;
  opacity: 1;
  line-height: 20px;
  margin-bottom: 14px;
  list-style: none;
}

.node-page .field-name-body ul > li:before,
.node-blog-entry .field-name-body ul > li:before,
.node-article .field-name-body ul > li:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 2px solid #3e3e3e;
  border-radius: 10px;
  position: relative;
  left: -86px;
  margin-right: -12px;
  top: 3px;
}

.node-page .field-name-body ol,
.node-blog-entry .field-name-body ol,
.node-article .field-name-body ol {
  counter-reset: number;
  list-style-type: none;
  margin: 40px 0 35px;
}

.node-page .field-name-body ol > li,
.node-blog-entry .field-name-body ol > li,
.node-article .field-name-body ol > li {
  background: url("../../src/svg/dotts.svg") no-repeat;
  background-size: 54px 2px;
  background-position: 20px 22px;
  padding-left: 92px;
  text-align: left;
  font: italic normal 300 18px/34px Roboto;
  letter-spacing: -0.15px;
  color: #3e3e3e;
  opacity: 1;
  line-height: 20px;
  margin-bottom: 8px;
  list-style: none;
}

.node-page .field-name-body ol > li:before,
.node-blog-entry .field-name-body ol > li:before,
.node-article .field-name-body ol > li:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  position: relative;
  left: -92px;
  margin-right: -12px;
  counter-increment: number;
  content: counter(number) "\a0";
  font: normal normal normal 18px/34px Roboto;
}

article .content a[href^="#"]:not([href="#"]):not(.btn) {
  border-bottom: unset;
}

.node-page .field-name-body ol ul,
.node-blog-entry .field-name-body ol ul,
.node-article .field-name-body ol ul {
  margin-left: -60px;
}

body {
  background-color: #fff;
}
.node-page.view-mode-full .field-name-body h2,
.node-blog-entry .field-name-body h2,
.node-article .field-name-body h2 {
  margin-bottom: 10px;
}

.node-blog-entry .field-name-body h3,
.node-article .field-name-body h3 {
  margin-bottom: 4px;
}

.node-blog-entry .field-name-body h4,
.node-article .field-name-body h4 {
  margin-bottom: 8px;
  font-weight: 500;
}

.node-blog-entry .field-name-body a,
.node-article .field-name-body a {
  &:hover {
    text-decoration: underline;
  }

}
article .content a[href^="#"]:not([href="#"]):not(.btn) {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}



/* blockquote */

article .content blockquote {
  border-top: 1px dashed #707070;
  border-bottom: 1px dashed #707070;
  border-left: 0;
  padding-left: 88px;
  padding-top: 30px;
  background: url("../../src/svg/quote.svg") no-repeat 2px 37px;
  margin-bottom: 38px;
  padding-bottom: 33px;
}

/* comments */
.comment {
  height: unset;
}

.not-front footer#footer {
  background-color: #fff;
  padding-bottom: 30px;
}

.view-related-publications {
  padding-bottom: 20px;
}

article {
  position: relative;
}
article .ya-share2 {
  position: absolute;
  right: 0;
  margin-top: 35px;
}

.comment-title {
  margin-top: 20px;
}

#burger_toggle {
  position: fixed;
  @media (--middle-viewport) {
    position: absolute;
  }
}

.not-front #block-menu-menu-blog .menu li a:hover:before,
#block-menu-menu-blog .menu li a.active:before {
  background-color: #fff;
}
.not-front #block-menu-menu-blog {
  border-left: 4px solid #fff;
}
.not-front #block-menu-menu-blog .menu li a:hover:after,
#block-menu-menu-blog .menu li a.active:after {
  color: #fff;
}

.not-front #sidebar-first:not(.animate__slideInLeft) #block-block-6 {
  position: fixed;
  left: inherit;
  bottom: 40px;
}

.pager .pager-item:first-child::before {
  display: none;
}
.pager .pager-item:first-child a {
  padding-left: 0;
}
.pager .pager-item a:hover {
  background: transparent;
}

#sidebar-first {
  position: fixed;
}

.page-node-1432 #page-title,
.page-node-916 #page-title,
.page-node-1443 #page-title,
.page-node-1365 #page-title,
.page-user #page-title,
.page-node-1483 #page-title {
  font-weight: 700;
  line-height: 1.1;
}

.not-front.page-node-1483 #main > .row {
  width: 100%;
}

.page-node-1483 #main #block-system-main .content {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding-top: 1px;
}

.success-title {
  text-align: center;
  font: normal normal normal 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #b1c52b;
  opacity: 1;
  margin-top: 33px;
}
.success-title-inner {
  display: inline-block;
  border-bottom: 1px dashed #b1c52b;
  padding-bottom: 13px;
  margin-bottom: 24px;
}
.success-subtitle {
  text-align: center;
  font: normal normal 300 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982;
  opacity: 1;
  margin-bottom: 25px;
}
.success-footer {
  text-align: center;
  font: normal normal 300 14px/18px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 50px;
}
.success-footer a {
  text-decoration: underline;
}

.not-front .nodeTeaser__readMore {
  display: none;
}

.nodeTeaser__article .nodeTeaser__author-image,
.nodeTeaser__blog .nodeTeaser__author-image {
  display: block;
}

.nodeTeaser__article .iconSvg-user,
.nodeTeaser__blog .iconSvg-user {
  display: none;
}

.nodeTeaser__article .nodeTeaser__author-name span,
.nodeTeaser__blog .nodeTeaser__author-name span {
  margin-left: 0;
}
.nodeTeaser__article .nodeTeaser__author,
.nodeTeaser__blog .nodeTeaser__author {
  display: flex;
}
.nodeTeaser__article .nodeTeaser__author,
.nodeTeaser__blog .nodeTeaser__author {
  margin-bottom: 9px;
}
.nodeTeaser__article .nodeTeaser__author-info,
.nodeTeaser__blog .nodeTeaser__author-info {
  padding-left: 19px;
}

.locale-untranslated {
  text-decoration: none;
}

#block-views-articles-block-articles .nodeTeaser__blog {
  height: 275px;
  overflow: hidden;
}

.page-taxonomy h1#page-title {
  display: none;
}

sup {
  vertical-align: super;
  font-size: smaller;
}
sub {
  vertical-align: sub;
  font-size: smaller;
}

.btn__blue {
  border: 1px solid #026fc2;
}
.btn__blue:hover {
  box-shadow: 0px 11px 31px #b1b1b1;
}

.nodeTeaser__article {
  @media (min-width: 768px) {
    padding: 21px 30px 0px;
  }
}

.taxonomy__page__tag .nodeTeaser__article,
.taxonomy__page__tag .nodeTeaser__blog {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-radius: 3px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.page-taxonomy-term.sidebar__custom_style
  .nodeTeaser__article
  .page-taxonomy-term.sidebar__custom_style
  .nodeTeaser__blog {
  border: none;
  margin-bottom: 0px;
  border-radius: 0px;
}

#main > .row {
  width: 100%;
  @media (--middle-viewport) {
    margin: 0;
  }
}

.webform-client-form-1 {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px;
  border-top: 4px solid $green;
  margin-top: 49px;
}
.webform-client-form-1365 .webform-component--imya,
.webform-client-form-1365 .webform-component--name {
  width: 332px;
  display: inline-block;
}
@media (max-width: 1136px) {
  .webform-client-form-1365 .webform-component--imya,
  .webform-client-form-1365 .webform-component--name {
    width: 100%;
  }
}

.webform-client-form-1365 {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px;
  border-top: 4px solid #b1c52b;
  margin-top: 49px;
}

.webform-client-form-1365 .form-actions input.form-submit,
.webform-client-form-1 .form-actions input.form-submit {
  background: #1487c5 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000033;
}

.webform-client-form-1365 .webform-component--e-mail,
.webform-client-form-1365 .webform-component--contact-info {
  width: 310px;
  display: inline-block;
  margin-left: 10px;
}

@media (max-width: 1136px) {
  .webform-client-form-1365 .webform-component--e-mail,
  .webform-client-form-1365 .webform-component--contact-info {
    width: 100%;
    margin-left: 0;
  }
}

figcaption {
  text-align: left;
  font: italic normal 300 15px/21px Roboto;
  letter-spacing: 0px;
  color: #54585c;
}

#user-register-form input:not(input[type="submit"])::placeholder {
  line-height: 15px;
}

#block-views-articles-block-articles
  .views-row-1
  .nodeTeaser
  .nodeTeaser__content
  .tex2jax {
  overflow: hidden;
}

#block-menu-menu-blog {
  max-width: 350px;
}
