.nodeTeaser {
  &.nodeTeaser__resume {
    background: #f7fafc;
    color: $blueCustom;
    padding: 26px 17px 25px;
    position: relative;
    .nodeTeaser__resume-left.col-md-3 {
      padding-left: 0;
      padding-right: 17px;
      @media (--middle-viewport) {
        padding-right: 0;
      }
    }
    .nodeTeaser__title {
      a {
        font: normal normal normal 34px/40px Roboto;
        color: #0c5982;
        b {
          display: block;
        }
      }
    }
    .soc-links-wrapper {
      padding-left: 12px;
      margin-bottom: 7px;
      @media (--middle-viewport) {
        padding-left: 0;
      }
      svg,
      .a {
        fill: $green !important;
      }
      a {
        margin-left: 6px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .wrapper-mail {
      padding-left: 15px;
      font: normal normal normal 14px/24px Roboto;
      @media (--middle-viewport) {
        padding-left: 0;
      }
    }
    .nodeTeaser__resume-right.col-md-9 {
      padding: 0;
      padding-top: 5px;
    }
    .field-name-field-photo {
      margin-bottom: 21px;
      img {
        display: block;
        width: 132px;
        height: 132px;

        border-radius: 50%;
      }
    }
    .nodeTeaser__title {
      font: normal normal bold 34px/40px Roboto;
      @media (--middle-viewport) {
        font: normal normal bold 20px/20px Roboto;
        margin-bottom: 0;
      }
    }
    .nodeTeaser__resumeDesc {
      font: normal normal bold 14px/24px Roboto;
      padding-top: 7px;
    }
    .nodeTeaser__resumeShort {
      font: normal normal bold 14px/24px Roboto;
    }
    .field-name-body {
      font: normal normal 300 14px/24px Roboto;
      color: $blueCustom;
      a {
        color: $blueCustom;
      }
    }
    .nodeTeaser__resume-top {
      margin: 0;
      margin-bottom: 9px;
    }
  }
  .link__more {
    position: absolute;
    right: 0;
    bottom: 0;
    position: absolute;
    right: 15px;
    bottom: 16px;
    font: 14px/16px Roboto;
    text-decoration: underline;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.nodeFull {
  &__resume {
    .field-name-body {
      font: normal normal 300 18px/24px Roboto;
      letter-spacing: 0px;
      color: #434343;
    }
  }
}

.view-resume.view-display-id-block {
  .view-content {
    padding: 0 0 0 14px;
    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }
}
