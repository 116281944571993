.front {
  .page-header {
    height: 100vh;
  }
  .page-header-bg {
    background: url(../images/main-header-bg.png) no-repeat;
    background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 0;
  }
  .page-header-bg-name {
    background: url(../images/front-name.png) no-repeat;
    background-size: auto;
    background-position: 44% 80%;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 0;
    @media (--middle-viewport) {
      display: none;
    }
  }
}
#navbar {
  padding: 16px 0 9px;
  height: auto;
  .region {
    a {
      color: #fff;
      font: normal normal normal 12px/16px Roboto;
      letter-spacing: 0.19px;
      text-transform: uppercase;
    }
  }
}
