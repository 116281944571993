.page-user {
  #main {
    & > .row {
      width: 100%;
      @media (--middle-viewport) {
        margin: 0;
      }
    }
    .card-form {
      max-width: 100%;
      border-radius: 0;
      box-shadow: none;
    }
    #block-system-main {
      .content {
        background-color: #ffffff;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        .messages {
          background: #FFFFFF;
          font-weight: lighter;
          width: 352px;
          margin: 0 auto;
          padding: 15px 0 0 0;
          text-align: center;
          ul {
            padding-left: 0;
          }
          @media (--middle-viewport) {
            width: 100%;
          }
        }
        .messages-error {
          color: #ff0000;
          padding-left: 0;
        }
        .messages-error::before {
          content: "";
        }
      }
    }
  }
}
