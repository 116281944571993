.views-row-add-border {
  .views-row {
    border: 1px solid $mainColor;
    border-radius: 3px;
  }
  .views-row-1 {
    border: unset;
  }
}

.front {
  .views-row-add-border {
    .views-row-1 {
      border: 1px solid $mainColor;
      border-radius: 3px;
    }
  }
}

.view-blog {
  &.view-display-id-page {
    .views-row {
      margin-bottom: 10px;
    }
  }
}
.view-articles {
  &.view-display-id-block_articles {
    .nodeTeaser {
      padding: 24px 17px 0px;
      .iconSvg-time {
        width: 16px;
        height: 16px;
      }
      .nodeTeaser__author {
        margin-bottom: 7px;
      }
      .nodeTeaser__author-name {
        span {
          margin-left: 11px;
        }
      }
      .nodeTeaser__author-info {
        padding-left: 0;
      }
      .nodeTeaser__author-image {
        display: none;
      }
      .iconSvg-user {
        display: block;
      }
    }
  }
  &.view-display-id-block_1 {
    .nodeTeaser {
      padding-top: 40px;

      .nodeTeaser__readMore {
        text-align: center;
      }
      .nodeTeaser__author {
        justify-content: center;
      }
      .nodeTeaser__author-info {
        width: 150px;
      }
      .nodeTeaser__title {
        text-align: center;
        padding: 0 63px;
        @media (max-width: 768px) {
          padding: 0;
          text-align: left;
        }
      }
      .nodeTeaser__content {
        text-align: center;
        padding: 0 191px;
        @media (max-width: 768px) {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
  &.view-display-id-block_articles {
    .view-content {
      display: flex;
      flex-wrap: wrap;
    }
    .field-name-field-image {
      display: none;
    }
    .views-row {
      margin-left: 16px;
      position: relative;
      height: 360px;
      .nodeTeaser {
        @media (--middle-viewport) {
          padding: 15px 0;
        }
      }
      @media (--big-viewport) {
        margin-left: 0;
        margin-bottom: 20px;
      }
      @media (--middle-viewport) {
        height: auto;
      }
    }
    .views-row-1 {
      margin-left: 0;
    }
    .col-md-4 {
      @media (min-width: 1136px) {
        flex: 0 0 32.333%;
      }
    }
  }
  &.view-display-id-page {
    .views-row {
      margin-bottom: 10px;
    }
    .nodeTeaser__readMore {
      display: none;
    }
  }
  .block__title {
    font: normal normal 300 40px/50px Roboto;
    color: $blueCustom;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 31px;
    letter-spacing: 0;
    @media (--middle-viewport) {
      font: normal normal 300 20px/30px Roboto;
      display: block;
      margin-bottom: 20px;
    }
    .more-link {
      margin-left: 10px;
      a {
        font: normal normal normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #35b1c4;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.blue-row {
  background: $blueCustom;
  border: 1px solid $articles-border;
  color: #fff;
  border-radius: 3px;
  width: 100%;
  &.views-row {
    height: auto;
    margin-left: 0;
    border: none;
  }
  .nodeTeaser {
    border: 1px solid #2bb1c5;
    border-radius: 3px;
  }
}

.front {
  .views-row-add-border {
    .views-row-1.blue-row {
      border: unset;
      .nodeTeaser {
        border: unset;
        border-radius: unset;
      }
    }
  }
}

.view-resume {
  .view-content {
    padding: 0 81px 0 14px;
    @media (--middle-viewport) {
      padding: 0;
    }
  }
}

#block-views-resume-block {
  border-top: 1px dotted #7a7a7a;
  padding-top: 10px;
  & > h2 {
    margin-bottom: 19px;
  }
}

.view-related-publications {
  border-top: 1px dotted #7a7a7a;
  border-bottom: 1px dotted #7a7a7a;
  .view-header {
    padding-top: 11px;
    margin-bottom: 14px;
  }
  .views-row {
    margin-bottom: 20px;
    a {
      font: normal normal normal 18px/26px Roboto;
      letter-spacing: -0.5px;
      color: #1178af;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.view-welcome-text {
  .welomeTeaser__content {
    padding-bottom: 25px;
  }
}
