#footer {
  background: transparent;
  box-shadow: none;
  .inner {
    border-top: 1px dotted $dottedBorderColor;
    padding-top: 23px;
    position: relative;
  }
  .form-item {
    margin-top: 11px;
    margin-bottom: 0;
  }
  .form-text {
    border: 1px solid #d1dbe3;
    padding: 5px 16px;
    border-radius: 5px;
    height: 60px;
    @media (--middle-viewport) {
      box-sizing: border-box;
    }
  }
  form {
    width: 444px;
    position: relative;
    @media (--middle-viewport) {
      width: 100%;
    }
  }
  .form-submit {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 120px;
    padding: 0 10px;
    z-index: 2;
  }
}
#block-block-7 {
  width: 100%;
}
.block-simplenews {
  margin-bottom: 21px;
}

.subscribe__title {
  font: normal normal normal 18px/26px Roboto;
  letter-spacing: -0.5px;
  color: $blueCustom;
  span {
    font: italic normal normal 14px/24px Roboto;
    letter-spacing: 0px;
    color: $dottedBorderColor;
  }
}

.soc-links-wrapper-mail {
  font: normal normal normal 14px/24px Roboto;
  color: #2bb1c5;
  margin-left: 18px;
}
.soc-links-wrapper {
  a {
    margin-left: 6px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.soc-links-wrapper,
.soc-links-wrapper-mail {
  display: inline-block;
  vertical-align: top;
}

#block-custom-solut-logo-svg-bottom {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 66px;
  svg {
    width: 50px;
    height: 50px;
  }
  @media (--middle-viewport) {
    position: absolute;
    right: 15px;
    bottom: 0;
    margin-bottom: 0;
    top: auto;
  }
}
.region-footer-content {
  .menu {
    li {
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 21px;
        width: 1px;
        background: $articles-border;
      }
      a {
        font: normal normal normal 14px/16px Roboto;
        letter-spacing: -0.5px;
        color: #2bb1c5;
        padding: 0 18px;
      }
      &.first {
        a {
          @media (max-width: 1136px) {
            padding-left: 0;
          }
        }
      }
      &.last {
        @media (max-width: 394px) {
          width: 100%;
        }
        &:after {
          display: none;
        }
        a {
          @media (max-width: 394px) {
            padding-left: 0;
          }
        }
      }
    }
  }
  .block-menu {
    padding-top: 17px;
    margin-bottom: 26px;
    @media (--middle-viewport) {
      padding-top: 0;
    }
  }
}

#block-block-9 {
  font: italic normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #bfbfbf;
  text-align: right;
  width: 100%;
  @media (--big-viewport) {
    text-align: left;
    margin-bottom: 71px;
  }
}

.logged-in {
  #footer .form-submit {
    position: static;
    margin-left: 0;
    margin-bottom: 0;
  }
}
