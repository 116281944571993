input.form-submit {
  width: 120px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  background: $blueCustom;
  border-radius: 5px;
  text-transform: inherit;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.block-webform {
  & > h2 {
    margin-bottom: 21px;
  }
  & > .content {
    position: relative;
    padding: 24px 22px 27px;

    box-shadow: 0px 10px 20px #0000001a;
    border-radius: 0px 0px 7px 7px;
    &:before {
      content: "";
      display: block;
      height: 4px;
      background: $green;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"],
.form-item textarea {
  border: 1px solid #d1dbe3;
  padding: 5px;
  font: normal normal 300 14px/9px Roboto;
  color: #083c50;
  border-radius: 5px;
  display: block;
  width: 100%;
  max-width: 100%;
}
.form-item textarea {
  height: 120px;
  width: 100%;
  display: block;
  max-width: inherit;
  line-height: 1.3;
}
.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"] {
  height: 54px;
}

.form-item {
  margin: 0;
  position: relative;
  label {
    position: absolute;
    left: 15px;
    top: 20px;
  }
  &.form-type-checkbox,
  &.form-type-file,
  &.form-type-select {
    label {
      position: static;
    }
  }
  &.form-type-password {
    label {
      top: 72px;
    }
  }

  &.webform-component--message {
    label {
      top: 7px;
    }
  }
}

.comment-form {
  .form-item {
    &.form-type-item {
      label {
        position: static;
      }
    }
  }
  .filter-wrapper {
    display: none;
  }
  .form-actions {
    margin-top: 30px;
    text-align: left;
  }
}

.form-item:focus {
  color: red;
}

.form-actions {
  text-align: center;
  input.form-submit {
    width: auto;
    height: 40px;
    color: #fff;
    font-size: 14px;
    background: #0c5982;
    border-radius: 5px;
    text-transform: inherit;
    margin: 0;
    padding: 8px 46px;
    border-radius: 5px;
  }
}

.form-item {
  margin-bottom: 17px;
}
.webform-client-form-1 {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px;
  border-top: 4px solid $green;
  margin-top: 49px;
}
.webform-client-form-1,
.webform-client-form-1474 {
  .webform-component--imya,
  .webform-component--name {
    width: 332px;
    display: inline-block;
    @media (--big-viewport) {
      width: 100%;
    }
  }
  .webform-component--e-mail,
  .webform-component--contact-info {
    width: 310px;
    display: inline-block;
    margin-left: 10px;
    @media (--big-viewport) {
      width: 100%;
      margin-left: 0;
    }
  }
}
.captcha {
  width: 352px;
  margin: 0 auto 17px;
  @media (--middle-viewport) {
    width: 100%;
  }
}

.card-form {
  .form-item,
  legend {
    width: 352px;
    margin: 0 auto 17px;
    @media (--middle-viewport) {
      width: 100%;
    }
  }
  .user-icon {
    display: none;
  }
}
.divider-top {
  border-top: none;
}
.card-item:last-child {
  margin-bottom: 0;
}
.card-actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0;
  input.form-submit {
    padding: 0 67px;
    background: #1487c5 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000033;
    transition: all 0.3s ease;
    &:hover {
      background: $blueCustom;
    }
  }
}

.btn.pull-right {
  font: normal normal 300 14px/24px Roboto;
  color: $blueCustom;
  text-transform: inherit;
}

#user-register-form {
  input:not(.form-submit),
  .password-strength,
  .password-suggestions,
  .password-confirm {
    width: 100%;
  }

  @media (--small-viewport) {
    .captcha {
      position: absolute;
      left: 0;
      right: 0;
      width: 304px;
      height: 80px;
    }
    .captcha + div {
      margin-top: 100px;
    }
  }
}

.comment-form {
  #edit-actions {
    margin-bottom: 30px;
  }
}

#edit-preview {
  margin-left: 20px;
}

.search-form {
  .form-item-keys {
    label {
      display: none;
    }
  }
}

#block-webform-client-block-1474 .content {
  border-top: 4px solid #afc32a;
  padding-top: 19px;
}

.webform-client-form-1474 {
  .form-actions input.form-submit {
    padding: 8px 66px;
    background: #1487c5;
    transition: all 0.3s ease;
    &:hover {
      background: $blueCustom;
    }
  }
}
