@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=cyrillic-ext");
/* break points */
/* mixins and functions */
/* break points */
@custom-media --big-viewport (max-width: 1136px);
@custom-media --middle-viewport (max-width: 768px);
@custom-media --small-viewport (max-width: 480px);
/*Fonts*/
/*Colors*/
/* break points */
/* mixins and functions */
/*
 *
 * Uncomment and set these variables to customize the grid.
 *
 * :root {
 *  --gutter-width: 1rem;
 *  --outer-margin: 2rem;
 *  --gutter-compensation: calc( var( --gutter-width / 2 ) * -1 );
 *  --half-gutter-width: calc( var( --gutter-width / 2 ) );
 *  --screen-xs-min: 30em;
 *  --screen-sm-min: 48em;
 *  --screen-md-min: 62em;
 *  --screen-lg-min: 75em;
 *  --container-sm: calc( 45rem + var( --gutter-width ) );
 *  --container-md: calc( 60rem + var( --gutter-width ) );
 *  --container-lg: calc( 70rem + var( --gutter-width ) );
 * }
 *
*/
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px; }

.row-wrapper {
  padding-right: 12px;
  padding-left: 12px; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.row.reverse {
  flex-direction: row-reverse; }

.col.reverse {
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 12px;
  padding-left: 12px; }

.col-xs {
  flex: 1 0 0;
  max-width: 100%; }

.col-xs-1 {
  flex: 0 0 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  flex: 0 0 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-xs-4 {
  flex: 0 0 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  flex: 0 0 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-xs-7 {
  flex: 0 0 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  flex: 0 0 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-xs-10 {
  flex: 0 0 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  flex: 0 0 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 768px) {
  .container {
    width: 768px; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 12px;
    padding-left: 12px; }
  .col-sm {
    flex: 1 0 0;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 1136px) {
  .container {
    width: 1136px; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 12px;
    padding-left: 12px; }
  .col-md {
    flex: 1 0 0;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 1256px) {
  .container {
    width: 1256px; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 12px;
    padding-left: 12px; }
  .col-lg {
    flex: 1 0 0;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    flex: 0 0 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    flex: 0 0 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    flex: 0 0 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    flex: 0 0 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1.4;
  font-size: 18px;
  font-family: "Roboto", sans-serif; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: #1487c5; }
  a:hover {
    text-decoration: underline; }

.page-title,
h1 {
  color: #0c5982;
  font-size: 40px;
  font-weight: 300; }

h2,
h3,
h4,
h5,
h6 {
  color: #0c5982;
  font-weight: 600; }

h2 {
  font-size: 24px; }

h3 {
  font: normal normal normal 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 14px; }

h1 + h1,
h1 + h2,
h1 + h3,
h1 + h4,
h1 + h5,
h1 + h6,
.h1 + h1,
.h1 + h2,
.h1 + h3,
.h1 + h4,
.h1 + h5,
.h1 + h6,
h2 + h1,
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
.h2 + h1,
.h2 + h2,
.h2 + h3,
.h2 + h4,
.h2 + h5,
.h2 + h6 {
  margin-top: 0; }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.animate__slideInLeft {
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-fill-mode: both; }

@keyframes slideInTop {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.animate__slideInTop {
  animation-name: slideInTop;
  animation-duration: 1s;
  animation-fill-mode: both; }

@keyframes slideToTop {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, -100%, 0); } }

.animate__slideToTop {
  animation-name: slideToTop;
  animation-duration: 1s;
  animation-fill-mode: both; }

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(-100%, 0, 0); } }

.animate__slideOutLeft {
  animation-name: slideOutLeft;
  animation-duration: 1s;
  animation-fill-mode: both; }

.paragraphs-item-resume {
  border-top: 1px dotted #7a7a7a;
  margin-bottom: 30px;
  padding-top: 19px; }
  .paragraphs-item-resume .paragraphs-items-field-resume-item-link {
    padding-left: 185px; }
    @media (--middle-viewport) {
      .paragraphs-item-resume .paragraphs-items-field-resume-item-link {
        padding-left: 0; } }
  .paragraphs-item-resume .field-name-field-resume-title {
    font: normal normal 300 34px/46px Roboto;
    color: #0c5982;
    width: 505px;
    margin-bottom: 78px; }
    @media (--middle-viewport) {
      .paragraphs-item-resume .field-name-field-resume-title {
        width: 100%;
        margin-bottom: 20px;
        font: normal normal 300 20px/25px Roboto; } }
  .paragraphs-item-resume .field-name-field-resume-item-title {
    font: normal normal normal 40px/50px Roboto;
    color: #a9be3b;
    position: relative;
    margin-bottom: 15px; }
    .paragraphs-item-resume .field-name-field-resume-item-title:before {
      content: "";
      display: block;
      width: 200px;
      height: 1px;
      background: #a9be3b;
      position: absolute;
      left: 0;
      bottom: 0; }
  .paragraphs-item-resume .field-name-field-resume-item-content {
    padding-left: 184px; }
    @media (--middle-viewport) {
      .paragraphs-item-resume .field-name-field-resume-item-content {
        padding-left: 0; } }

.paragraphs-item-list {
  font: italic normal 300 18px/34px Roboto;
  color: #3e3e3e; }
  .paragraphs-item-list .field-item {
    padding-left: 85px;
    position: relative; }
    .paragraphs-item-list .field-item:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #3e3e3e;
      left: 0;
      position: absolute;
      top: 14px; }
    .paragraphs-item-list .field-item:before {
      content: "";
      display: inline-block;
      background-image: url(../images/sprite.png);
      background-position: -44px 0px;
      width: 54px;
      height: 2px;
      vertical-align: middle;
      position: absolute;
      left: 17px;
      top: 19px; }

.paragraphs-item-img-descr img {
  border-radius: 5px; }

.field-name-field-paragraphs > .field-items > .field-item {
  margin-bottom: 45px; }
  @media (--middle-viewport) {
    .field-name-field-paragraphs > .field-items > .field-item {
      margin-bottom: 20px; } }

.paragraphs-item-text-with-indent {
  border-top: 1px dashed #707070;
  border-bottom: 1px dashed #707070;
  padding: 30px 0 15px 88px; }
  @media (--middle-viewport) {
    .paragraphs-item-text-with-indent {
      padding: 0px; } }
  .paragraphs-item-text-with-indent p {
    margin-bottom: 14px; }

.field-name-field-tags .field-label {
  font: normal normal 300 34px/46px Roboto;
  letter-spacing: -0.94px;
  color: #0c5982; }

.paragraphs-item-text-2-columns > .content {
  display: flex;
  flex-wrap: wrap;
  height: 261px; }
  @media (--big-viewport) {
    .paragraphs-item-text-2-columns > .content {
      flex-direction: column;
      height: auto; } }

.paragraphs-item-text-2-columns .field {
  width: 50%;
  height: 100%;
  position: relative; }
  @media (--big-viewport) {
    .paragraphs-item-text-2-columns .field {
      width: 100%; } }

.paragraphs-item-text-2-columns .field-name-field-text-left-col {
  font: normal normal 300 40px/50px Roboto;
  letter-spacing: 0px;
  color: #0c5982; }
  @media (--middle-viewport) {
    .paragraphs-item-text-2-columns .field-name-field-text-left-col {
      font: normal normal 300 20px/30px Roboto; } }
  .paragraphs-item-text-2-columns .field-name-field-text-left-col:before {
    content: "";
    display: block;
    width: 84%;
    height: 2px;
    background-color: #a9be3b;
    left: 0;
    bottom: 0;
    position: absolute; }
  @media (--middle-viewport) {
    .paragraphs-item-text-2-columns .field-name-field-text-left-col {
      margin-bottom: 20px; }
      .paragraphs-item-text-2-columns .field-name-field-text-left-col:before {
        display: none; } }
  .paragraphs-item-text-2-columns .field-name-field-text-left-col p {
    margin-bottom: 0;
    color: #0c5982; }

.paragraphs-item-text-2-columns .field-name-field-text-right-col p:first-child {
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #7a7a7a;
  margin-bottom: 35px; }
  @media (--middle-viewport) {
    .paragraphs-item-text-2-columns .field-name-field-text-right-col p:first-child {
      margin-bottom: 20pxs; } }

.paragraphs-item-text-2-columns .field-name-field-text-right-col p:last-child {
  font: italic normal normal 20px/30px Georgia;
  color: #0c5982; }

.paragraphs-item-2-columns-lists > .content {
  display: flex;
  flex-wrap: wrap; }
  @media (--middle-viewport) {
    .paragraphs-item-2-columns-lists > .content {
      flex-direction: column; } }

.paragraphs-item-2-columns-lists .paragraphs-items-field-right-col-list,
.paragraphs-item-2-columns-lists .field-name-field-text-left-col {
  width: 50%; }
  @media (--middle-viewport) {
    .paragraphs-item-2-columns-lists .paragraphs-items-field-right-col-list,
    .paragraphs-item-2-columns-lists .field-name-field-text-left-col {
      width: 100%; } }

.paragraphs-item-2-columns-lists .field-name-field-text-left-col {
  padding-right: 77px; }
  @media (--middle-viewport) {
    .paragraphs-item-2-columns-lists .field-name-field-text-left-col {
      padding-right: 0; } }
  .paragraphs-item-2-columns-lists .field-name-field-text-left-col ol {
    list-style: decimal;
    padding-left: 11px; }
    .paragraphs-item-2-columns-lists .field-name-field-text-left-col ol li {
      font: normal normal normal 14px/16px Roboto;
      color: #7a7a7a;
      margin-bottom: 16px;
      padding-left: 13px;
      padding-right: 54px;
      position: relative; }
      @media (--middle-viewport) {
        .paragraphs-item-2-columns-lists .field-name-field-text-left-col ol li {
          padding-right: 0; } }
      .paragraphs-item-2-columns-lists .field-name-field-text-left-col ol li:after {
        content: "";
        display: inline-block;
        background-image: url(../images/sprite.png);
        background-position: 0px 0px;
        width: 22px;
        height: 17px;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%); }
      .paragraphs-item-2-columns-lists .field-name-field-text-left-col ol li .line-text {
        text-decoration: line-through; }
      .paragraphs-item-2-columns-lists .field-name-field-text-left-col ol li.line-text-wrapper:after {
        background-position: -22px 0px; }
  .paragraphs-item-2-columns-lists .field-name-field-text-left-col .field-items {
    box-shadow: 0px 20px 40px #00000033;
    padding: 21px 30px 26px;
    border-radius: 5px; }
  .paragraphs-item-2-columns-lists .field-name-field-text-left-col h2 {
    font: normal normal bold 22px/30px Roboto;
    color: #0c5982;
    margin-bottom: 30px; }

.paragraphs-item-paragraph-with-logo {
  padding-top: 36px;
  padding-left: 92px; }
  .paragraphs-item-paragraph-with-logo svg {
    fill: #a9be3b; }
  @media (--middle-viewport) {
    .paragraphs-item-paragraph-with-logo {
      padding-top: 20px;
      padding-left: 0; } }
  .paragraphs-item-paragraph-with-logo .group-left {
    width: 28%; }
    @media (--middle-viewport) {
      .paragraphs-item-paragraph-with-logo .group-left {
        width: 100%; } }
  .paragraphs-item-paragraph-with-logo .group-right {
    width: 68%;
    padding-left: 18px; }
    @media (--middle-viewport) {
      .paragraphs-item-paragraph-with-logo .group-right {
        width: 100%;
        padding-left: 0; } }
  .paragraphs-item-paragraph-with-logo .field-name-field-par-title {
    font: normal normal 300 20px/30px Roboto;
    color: #0c5982;
    margin-bottom: 9px; }
    .paragraphs-item-paragraph-with-logo .field-name-field-par-title p {
      font: normal normal 300 20px/30px Roboto;
      color: #0c5982;
      margin-bottom: 0; }
  .paragraphs-item-paragraph-with-logo .paragraphs-item-list .field-item {
    font: normal normal normal 14px/24px Roboto;
    color: #7a7a7a;
    margin-bottom: 3px;
    padding-left: 91px; }
    .paragraphs-item-paragraph-with-logo .paragraphs-item-list .field-item:after {
      border: 1px solid #0c5982;
      top: 8px; }
    .paragraphs-item-paragraph-with-logo .paragraphs-item-list .field-item:before {
      left: 25px;
      top: 13px; }

.front .paragraphs-item-paragraph-with-logo {
  padding-left: 0;
  padding-top: 49px; }
  .front .paragraphs-item-paragraph-with-logo .group-left {
    width: 32%; }
    @media (--big-viewport) {
      .front .paragraphs-item-paragraph-with-logo .group-left {
        width: 100%; } }
  .front .paragraphs-item-paragraph-with-logo .group-right {
    padding-left: 13px; }
    @media (--big-viewport) {
      .front .paragraphs-item-paragraph-with-logo .group-right {
        width: 100%; } }
  .front .paragraphs-item-paragraph-with-logo svg {
    fill: #e4e4e4; }

input.form-submit {
  width: 120px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  background: #0c5982;
  border-radius: 5px;
  text-transform: inherit;
  -webkit-appearance: none;
  -webkit-border-radius: 0; }

.block-webform > h2 {
  margin-bottom: 21px; }

.block-webform > .content {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px; }
  .block-webform > .content:before {
    content: "";
    display: block;
    height: 4px;
    background: #a9be3b;
    position: absolute;
    left: 0;
    top: 0; }

.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"],
.form-item textarea {
  border: 1px solid #d1dbe3;
  padding: 5px;
  font: normal normal 300 14px/9px Roboto;
  color: #083c50;
  border-radius: 5px;
  display: block;
  width: 100%;
  max-width: 100%; }

.form-item textarea {
  height: 120px;
  width: 100%;
  display: block;
  max-width: inherit;
  line-height: 1.3; }

.form-item input[type="password"],
.form-item input[type="text"],
.form-item input[type="email"] {
  height: 54px; }

.form-item {
  margin: 0;
  position: relative; }
  .form-item label {
    position: absolute;
    left: 15px;
    top: 20px; }
  .form-item.form-type-checkbox label, .form-item.form-type-file label, .form-item.form-type-select label {
    position: static; }
  .form-item.form-type-password label {
    top: 72px; }
  .form-item.webform-component--message label {
    top: 7px; }

.comment-form .form-item.form-type-item label {
  position: static; }

.comment-form .filter-wrapper {
  display: none; }

.comment-form .form-actions {
  margin-top: 30px;
  text-align: left; }

.form-item:focus {
  color: red; }

.form-actions {
  text-align: center; }
  .form-actions input.form-submit {
    width: auto;
    height: 40px;
    color: #fff;
    font-size: 14px;
    background: #0c5982;
    border-radius: 5px;
    text-transform: inherit;
    margin: 0;
    padding: 8px 46px;
    border-radius: 5px; }

.form-item {
  margin-bottom: 17px; }

.webform-client-form-1 {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px;
  border-top: 4px solid #a9be3b;
  margin-top: 49px; }

.webform-client-form-1 .webform-component--imya,
.webform-client-form-1 .webform-component--name,
.webform-client-form-1474 .webform-component--imya,
.webform-client-form-1474 .webform-component--name {
  width: 332px;
  display: inline-block; }
  @media (--big-viewport) {
    .webform-client-form-1 .webform-component--imya,
    .webform-client-form-1 .webform-component--name,
    .webform-client-form-1474 .webform-component--imya,
    .webform-client-form-1474 .webform-component--name {
      width: 100%; } }

.webform-client-form-1 .webform-component--e-mail,
.webform-client-form-1 .webform-component--contact-info,
.webform-client-form-1474 .webform-component--e-mail,
.webform-client-form-1474 .webform-component--contact-info {
  width: 310px;
  display: inline-block;
  margin-left: 10px; }
  @media (--big-viewport) {
    .webform-client-form-1 .webform-component--e-mail,
    .webform-client-form-1 .webform-component--contact-info,
    .webform-client-form-1474 .webform-component--e-mail,
    .webform-client-form-1474 .webform-component--contact-info {
      width: 100%;
      margin-left: 0; } }

.captcha {
  width: 352px;
  margin: 0 auto 17px; }
  @media (--middle-viewport) {
    .captcha {
      width: 100%; } }

.card-form .form-item,
.card-form legend {
  width: 352px;
  margin: 0 auto 17px; }
  @media (--middle-viewport) {
    .card-form .form-item,
    .card-form legend {
      width: 100%; } }

.card-form .user-icon {
  display: none; }

.divider-top {
  border-top: none; }

.card-item:last-child {
  margin-bottom: 0; }

.card-actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0; }
  .card-actions input.form-submit {
    padding: 0 67px;
    background: #1487c5 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000033;
    transition: all 0.3s ease; }
    .card-actions input.form-submit:hover {
      background: #0c5982; }

.btn.pull-right {
  font: normal normal 300 14px/24px Roboto;
  color: #0c5982;
  text-transform: inherit; }

#user-register-form input:not(.form-submit),
#user-register-form .password-strength,
#user-register-form .password-suggestions,
#user-register-form .password-confirm {
  width: 100%; }

@media (--small-viewport) {
  #user-register-form .captcha {
    position: absolute;
    left: 0;
    right: 0;
    width: 304px;
    height: 80px; }
  #user-register-form .captcha + div {
    margin-top: 100px; } }

.comment-form #edit-actions {
  margin-bottom: 30px; }

#edit-preview {
  margin-left: 20px; }

.search-form .form-item-keys label {
  display: none; }

#block-webform-client-block-1474 .content {
  border-top: 4px solid #afc32a;
  padding-top: 19px; }

.webform-client-form-1474 .form-actions input.form-submit {
  padding: 8px 66px;
  background: #1487c5;
  transition: all 0.3s ease; }
  .webform-client-form-1474 .form-actions input.form-submit:hover {
    background: #0c5982; }

main {
  position: relative;
  z-index: 1;
  background-color: #fff; }

.bllue {
  color: #434343; }

.col-md-8.green-line {
  height: 4px; }

.page-node-1461 #page-title,
.page-node-337 #page-title {
  line-height: 1.1;
  font-size: 30px; }

#after-main {
  position: relative;
  z-index: 10;
  background-color: #fff; }

.icon {
  display: inline-block;
  vertical-align: middle; }

.btn {
  display: inline-block;
  text-align: center;
  border: none;
  outline: none;
  text-decoration: none; }

.btn-yellow {
  color: #fff;
  background: #c89b22;
  height: 50px;
  line-height: 50px;
  padding: 0 27px;
  font-weight: 600;
  border-radius: 24px;
  text-transform: uppercase; }
  .btn-yellow .icon {
    margin-right: 15px; }

#header {
  background-color: #cfcfcf; }

#page {
  display: block;
  position: relative;
  overflow-x: hidden; }

#main .block {
  margin: 15px 0; }
  #main .block#block-views-articles-block-1 {
    margin-bottom: 17px;
    border-top: 1px dotted #7a7a7a;
    padding-top: 38px; }
    @media (--middle-viewport) {
      #main .block#block-views-articles-block-1 {
        padding-top: 20px;
        margin-bottom: 20px; } }
  #main .block#block-views-articles-block-articles {
    margin-top: 0;
    margin-bottom: 56px; }
    @media (--middle-viewport) {
      #main .block#block-views-articles-block-articles {
        margin-bottom: 20px; } }
  #main .block#block-views-welcome-text-block {
    margin-top: 0;
    margin-bottom: 35px; }
    @media (--middle-viewport) {
      #main .block#block-views-welcome-text-block {
        margin-bottom: 20px; } }
  #main .block#block-views-resume-block {
    margin-bottom: 40px; }
    @media (--middle-viewport) {
      #main .block#block-views-resume-block {
        margin-bottom: 20px; } }
  #main .block#block-webform-client-block-1474 {
    margin-bottom: 57px; }
    @media (--middle-viewport) {
      #main .block#block-webform-client-block-1474 {
        margin-bottom: 20px; } }

img {
  max-width: 100%;
  height: auto; }

strong,
b {
  font-weight: 700; }

#main .block {
  margin: 12px 0; }

.one-sidebar #sidebar-first {
  order: 0; }

.green-line:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  background: #a9be3b; }

body {
  overflow-x: hidden; }

p {
  margin-bottom: 25px; }

h2 {
  font: normal normal 300 34px/46px Roboto;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  color: #0c5982; }

.pager .pager-item.item-previous > a span,
.pager .pager-item.item-first > a span,
.pager .pager-item.item-next > a span,
.pager .pager-item.item-next > span span,
.pager .pager-item.item-last > a span,
.pager .pager-item.item-last > span span {
  display: inline; }

.pager .pager-item.item-next > a::before,
.pager .pager-item.item-next > span::before,
.pager .pager-item.item-last > a::before,
.pager .pager-item.item-last > span::before,
.pager .pager-item.item-first > a::before,
.pager .pager-item.item-first > span::before,
.pager .pager-item.item-previous > a::before,
.pager .pager-item.item-previous > span::before {
  display: none; }

.pager-item.item-ellipsis {
  display: none; }

.pager .pager-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 39px 0; }

.pager .pager-item:before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 20px;
  background: #dddddd; }

.pager .pager-item > a,
.pager .pager-item > span {
  margin: 0;
  min-width: inherit; }

.pager .pager-item > a,
.pager .pager-item > span {
  height: 20px;
  padding: 0px 16px;
  vertical-align: top;
  font: normal normal 300 18px/26px Roboto;
  line-height: 20px; }

.pager .pager-item.item-first > a,
.pager .pager-item.item-previous > a,
.pager .pager-item.item-next > a,
.pager .pager-item.item-last > a {
  padding: 0 16px; }

.pager .pager-item.item-current > span {
  font: normal normal bold 18px/26px Roboto;
  color: #1b252e;
  line-height: 20px; }

.contactsData {
  padding-top: 12px; }
  .contactsData .svg-wrapper {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative; }
  .contactsData svg {
    fill: #a9be3b;
    width: 20px;
    height: 20px;
    margin-left: 4px; }
  .contactsData__row {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 6px; }
    @media (--middle-viewport) {
      .contactsData__row {
        flex-direction: column; } }
    .contactsData__row-face .contactsData__border {
      width: 150px; }
      @media (--big-viewport) {
        .contactsData__row-face .contactsData__border {
          display: none; } }
    .contactsData__row-email .contactsData__border {
      width: 216px; }
      @media (--big-viewport) {
        .contactsData__row-email .contactsData__border {
          display: none; } }
  .contactsData__label {
    font: normal normal 300 34px/46px Roboto;
    color: #0c5982;
    line-height: 1; }
  .contactsData__info {
    font: normal normal 300 18px/24px Roboto;
    letter-spacing: 0px;
    color: #1178af;
    margin-left: 14px; }
    @media (--middle-viewport) {
      .contactsData__info {
        margin-left: 0; } }
  .contactsData__border {
    border: 2px dashed #9d9d9d;
    height: 2px;
    margin-left: 15px;
    width: 130px; }
    @media (--big-viewport) {
      .contactsData__border {
        display: none; } }

.svg-wrapper::before,
.svg-wrapper::after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 0; }

.svg-wrapper::before {
  left: 5px;
  top: 16px;
  transform: rotate(34deg); }

.svg-wrapper::after {
  right: -3px;
  top: 16px;
  transform: rotate(-34deg); }

.registerLink {
  text-align: center; }
  .registerLink__label {
    font: normal normal 300 18px/24px Roboto;
    color: #0c5982; }

.form-item input[type="text"].error,
.form-item input[type="email"].error,
.form-item input[type="password"].error,
.form-item input[type="search"].error,
.form-item input[type="tel"].error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #ff0000;
  padding: 5px; }

.termsWrapper {
  font: normal normal 300 14px/18px Roboto;
  max-width: 376px;
  color: #0c5982;
  margin: auto;
  text-align: center; }
  .termsWrapper a {
    color: #1487c5;
    text-decoration: underline; }
    .termsWrapper a:hover {
      text-decoration: none; }

.card-form .form-item-uloginTitle {
  font: normal normal normal 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982;
  text-align: center;
  margin-bottom: 13px; }

.card-form .form-item-description {
  font: normal normal 300 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982;
  padding-top: 14px;
  text-align: center;
  border-top: 1px dotted #0c5982; }

.card-form .ulogin-buttons-container {
  display: inline-flex !important;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 21px; }

.g-recaptcha > div {
  max-width: 20px auto; }

.tabs.primary {
  position: fixed;
  left: 0;
  top: 350px;
  width: 150px;
  flex-wrap: wrap;
  border: 1px dotted #cfcfcf;
  background-color: #cfcfcf;
  padding: 5px;
  border-radius: 5px; }

.tabs ul li a,
ul.tabs li a {
  display: inline-block;
  line-height: inherit;
  padding: 5px;
  border-bottom: none;
  border-bottom-color: transparent;
  font-size: 14px;
  font-weight: 500;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.54);
  text-align: left; }
  .tabs ul li a:hover,
  ul.tabs li a:hover {
    color: #0c5982; }

p {
  color: #7a7a7a; }

table {
  background: transparent; }
  table td {
    border-top: 1px dotted #3e3e3e;
    border-right: 1px solid #3e3e3e;
    padding: 21px 10px;
    text-align: center; }
    table td:first-child {
      text-align: left;
      padding-left: 0;
      width: 278px; }
    table td:last-child {
      border-right: none;
      text-align: right;
      padding-right: 0; }
  table tr:first-child td {
    border-top: 1px solid #3e3e3e; }
  table tr:last-child td {
    border-bottom: 1px solid #3e3e3e; }

.row__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (--middle-viewport) {
    .row__wrapper {
      flex-direction: column;
      margin-bottom: 20px; } }

.not-front #block-system-main-menu .menu li a {
  font: normal normal 300 16px/16px Roboto;
  letter-spacing: 0.26px;
  color: #0c5982; }
  .not-front #block-system-main-menu .menu li a:hover {
    color: #0c5982; }
    .not-front #block-system-main-menu .menu li a:hover:before {
      color: #0c5982; }

#navbar {
  z-index: 1; }

@media (--middle-viewport) {
  .front #burger_toggle svg {
    fill: #c6d84e; } }

#burger_toggle {
  position: absolute;
  z-index: 10;
  left: 33px;
  top: 16px;
  cursor: pointer;
  width: 15px;
  height: 12px; }
  #burger_toggle svg {
    width: 100%;
    height: 100%;
    fill: #879a87; }

body {
  overflow-x: hidden; }

.view-cat .views-row {
  margin-bottom: 10px; }

.not-front #block-search-form .search-form-trigger svg {
  margin-left: 6px;
  margin-top: 6px; }

.page-node-916 #page-title,
.page-node-1443 #page-title,
.page-node-1365 #page-title,
.page-user #page-title {
  font-weight: 700;
  line-height: 1.1; }

.node-type-resume #page-title b {
  display: block; }
  .node-type-resume #page-title b:first-child {
    font-weight: 700;
    line-height: 1.1; }

#block-block-15,
#block-block-16,
#block-block-17,
#block-block-18,
#block-block-19 {
  font: normal normal bold 14px/24px Roboto;
  color: #014f80;
  max-width: 250px;
  padding-top: 5px;
  max-width: 250px; }
  #block-block-15 p,
  #block-block-16 p,
  #block-block-17 p,
  #block-block-18 p,
  #block-block-19 p {
    font: normal normal bold 14px/24px Roboto;
    color: #014f80; }
  #block-block-15 .soc-links-wrapper .mailto__link,
  #block-block-16 .soc-links-wrapper .mailto__link,
  #block-block-17 .soc-links-wrapper .mailto__link,
  #block-block-18 .soc-links-wrapper .mailto__link,
  #block-block-19 .soc-links-wrapper .mailto__link {
    display: block;
    margin-left: 0;
    padding-top: 11px; }

.page-node .sidebar {
  padding-left: 0; }

.page-node .page-title,
.page-node h1 {
  font-weight: 300; }

.sidebar__custom_style.not-front #block-search-form .search-form-trigger svg {
  margin-left: 10px;
  margin-top: 0; }

.sidebar__custom_style.not-front #sidebar-first {
  position: static;
  transform: inherit;
  background: transparent;
  height: 560px; }

.sidebar__custom_style.not-front .region-sidebar-first {
  padding-left: 0;
  padding-top: 9px; }

@media (min-width: 768px) {
  .sidebar__custom_style.not-front #block-menu-menu-menu-in-sidebar,
  .sidebar__custom_style.not-front #block-custom-solut-logo-svg-menu {
    display: none; } }

.sidebar__custom_style.not-front #block-menu-menu-blog {
  border-left-color: #a9be3b; }
  .sidebar__custom_style.not-front #block-menu-menu-blog > h2 {
    font: normal normal bold 24px/16px Roboto;
    letter-spacing: 0.38px;
    color: #434343;
    margin-bottom: 0px; }
    .sidebar__custom_style.not-front #block-menu-menu-blog > h2 a {
      color: #434343; }
      .sidebar__custom_style.not-front #block-menu-menu-blog > h2 a:hover {
        color: #434343;
        text-decoration: underline; }
  .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a {
    font: normal normal medium 18px/21px Roboto;
    letter-spacing: 0.34px;
    color: #0c5982;
    padding: 3px 19px;
    display: inline-block; }
    .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a:hover, .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a.active {
      color: #ffffff;
      background: #a9be3b; }
      .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a:hover:before, .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a.active:before {
        background: transparent; }
      .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a:hover:after, .sidebar__custom_style.not-front #block-menu-menu-blog .menu li a.active:after {
        color: #a9be3b; }

.mailto__link {
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #2bb1c5;
  margin-left: 20px; }

.breadcrumb {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: -0.5px;
  color: #434343; }
  .breadcrumb a,
  .breadcrumb i {
    float: left;
    line-height: 13px; }
  .breadcrumb i {
    font-size: 13px;
    margin: 0 3px; }
  .breadcrumb .fa-arrow-right {
    font-size: 8px;
    margin: 0 3px; }
  .breadcrumb .fa-level-down-alt {
    font-size: 8px;
    padding-top: 3px; }
    .breadcrumb .fa-level-down-alt:last-child {
      transform: translateY(4px); }
  .breadcrumb .fa-level-down-alt + a {
    clear: left;
    padding-left: 29px;
    padding-top: 3px; }
  .breadcrumb .fa-home {
    font-size: 11px; }

.breadcrumb {
  margin: 12px 0 12px; }

.not-front.page-node #main > .row {
  margin: 0; }

@media (--middle-viewport) {
  .nodeFull.nodeFull__resume > .row {
    margin: 0; } }

.page-taxonomy-term .nodeTeaser__blog {
  border: 1px solid #2bb1c5;
  margin-bottom: 20px;
  border-radius: 3px; }

.page-taxonomy-term.sidebar__custom_style .nodeTeaser__blog {
  border: none;
  margin-bottom: 0px;
  border-radius: 0px; }

.page-blog #page-title {
  display: none; }

@media (min-width: 1185px) {
  .not-front .region-navbar {
    height: 25px;
    position: relative; }
  .not-front #block-block-2 {
    position: absolute;
    right: 0; }
  .not-front h1 {
    max-width: 350px; }
  .not-front .region-header {
    height: 79px; }
  .not-front div#block-custom-solut-logo-svg {
    z-index: 3; }
  .not-front footer#footer {
    background-color: #fafafa;
    z-index: 2;
    position: relative; } }

.taxonomy__page__tag .nodeTeaser {
  padding-top: 19px; }
  .taxonomy__page__tag .nodeTeaser .nodeTeaser__author-info {
    padding-left: 20px; }
  .taxonomy__page__tag .nodeTeaser .nodeTeaser__author-name {
    transform: translateY(-2px);
    margin-bottom: 3px; }
  .taxonomy__page__tag .nodeTeaser .nodeTeaser__created {
    transform: translateY(-9px); }
    .taxonomy__page__tag .nodeTeaser .nodeTeaser__created .iconSvg-time {
      transform: translate(-3px, 1px); }
    .taxonomy__page__tag .nodeTeaser .nodeTeaser__created span {
      margin-left: 3px; }

.view-blog.view-display-id-page .nodeTeaser__title,
.view-cat.view-display-id-page .nodeTeaser__title {
  text-decoration: none; }
  .view-blog.view-display-id-page .nodeTeaser__title:hover,
  .view-cat.view-display-id-page .nodeTeaser__title:hover {
    text-decoration: underline; }

.view-blog.view-display-id-page .nodeTeaser,
.view-cat.view-display-id-page .nodeTeaser {
  padding-top: 19px; }
  .view-blog.view-display-id-page .nodeTeaser .nodeTeaser__author-info,
  .view-cat.view-display-id-page .nodeTeaser .nodeTeaser__author-info {
    padding-left: 20px; }
  .view-blog.view-display-id-page .nodeTeaser .nodeTeaser__author-name,
  .view-cat.view-display-id-page .nodeTeaser .nodeTeaser__author-name {
    transform: translateY(-2px);
    margin-bottom: 0px; }
  .view-blog.view-display-id-page .nodeTeaser .nodeTeaser__created,
  .view-cat.view-display-id-page .nodeTeaser .nodeTeaser__created {
    transform: translateY(-7px); }
    .view-blog.view-display-id-page .nodeTeaser .nodeTeaser__created .iconSvg-time,
    .view-cat.view-display-id-page .nodeTeaser .nodeTeaser__created .iconSvg-time {
      width: 11px;
      height: 11px; }
    .view-blog.view-display-id-page .nodeTeaser .nodeTeaser__created span,
    .view-cat.view-display-id-page .nodeTeaser .nodeTeaser__created span {
      margin-left: 3px; }

.view-blog.view-display-id-page .views-row-1 .nodeTeaser,
.view-cat.view-display-id-page .views-row-1 .nodeTeaser {
  padding-bottom: 30px;
  padding-top: 26px; }
  .view-blog.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author-info,
  .view-cat.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author-info {
    padding-left: 19px;
    transform: translateY(-2px); }
  .view-blog.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author-image,
  .view-cat.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author-image {
    margin-left: 0; }
  .view-blog.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author,
  .view-cat.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author {
    margin-bottom: 24px; }
  .view-blog.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author-name,
  .view-cat.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__author-name {
    font-size: 13px;
    transform: translateY(-1px);
    margin-bottom: 4px; }
  .view-blog.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__created span,
  .view-cat.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__created span {
    margin-left: 4px; }
  .view-blog.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__title,
  .view-cat.view-display-id-page .views-row-1 .nodeTeaser .nodeTeaser__title {
    font: normal normal normal 35px/40px Roboto;
    margin-bottom: 41px; }

.view-blog .nodeTeaser .nodeTeaser__readMore,
.view-cat .nodeTeaser .nodeTeaser__readMore {
  display: none; }

@media (min-width: 768px) {
  .view-blog.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__author-name,
  .view-cat.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__author-name {
    transform: translate(0px, -4px); } }

@media (min-width: 768px) {
  .view-blog.view-display-id-block_2 .nodeTeaser__created,
  .view-cat.view-display-id-block_2 .nodeTeaser__created {
    transform: translate(0px, -10px); } }

.view-blog.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__author,
.view-cat.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__author {
  margin-bottom: 20px; }

.view-blog.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title,
.view-cat.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title {
  font: normal normal normal 35px/41px Roboto;
  text-decoration: underline; }
  @media (--middle-viewport) {
    .view-blog.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title,
    .view-cat.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title {
      font: normal normal normal 20px/30px Roboto; } }
  .view-blog.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title:hover,
  .view-cat.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title:hover {
    text-decoration: none; }

.view-blog.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title,
.view-cat.view-display-id-block_2 .nodeTeaser-long .nodeTeaser__title {
  margin-bottom: 37px; }

.sidebar__custom_style #main > .row {
  width: 100%; }

.sidebar__custom_style #main .region-content {
  padding-left: 8px; }
  @media (--middle-viewport) {
    .sidebar__custom_style #main .region-content {
      padding-left: 0; } }

@media (min-width: 768px) {
  .i18n-ru #block-custom-solut-logo-svg .logo-site-name {
    font-size: 25px;
    line-height: 26px; }
  .i18n-ru .region-footer-content {
    padding-left: 114px; } }

@media (min-width: 768px) {
  #main {
    min-height: calc(100vh - 296px); } }

@media (min-width: 768px) {
  .page-node-1459 .green-line {
    padding-left: 16px; } }

@media (max-width: 768px) {
  .front__scroll,
  .paragraphs-item-2-columns-lists
.field-name-field-text-left-col
ol
li::after {
    display: none; } }

.math-inline .MathJax_Display {
  margin: 0; }

.page-search .green-line {
  padding-left: 4px; }

.page-search input.form-submit {
  margin-left: 0; }

.page-search .search-results .search-result {
  border: 1px solid #2bb1c5;
  border-radius: 3px;
  padding: 21px 30px 0px; }
  .page-search .search-results .search-result h3 a {
    color: #0c5982;
    font: normal normal normal 18px/26px Roboto; }

.page-search .search-results .search-snippet-info {
  font: normal normal normal 14px/24px Roboto;
  padding-left: 0; }

.page-search .search-results .search-info {
  display: none; }

.taxonomy__page__tag .green-line {
  padding-left: 4px; }

.taxonomy__page__tag #block-system-main .nodeTeaser {
  border: 1px solid #2bb1c5;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 21px 30px 20px; }

.page-user-register .form-item-name .description,
.page-user-register .form-item-mail .description {
  display: none; }

.page-user-register .form-item-pass-pass2 {
  display: none; }

.page-user-register .form-item-pass .description {
  display: none; }

#user-register-form .form-item input::-webkit-input-placeholder {
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50; }

#user-register-form .form-item input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50; }

#user-register-form .form-item input::-moz-placeholder {
  /* Firefox 19+ */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50; }

#user-register-form .form-item input:-ms-input-placeholder {
  /* IE 10+ */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50; }

#user-register-form .form-item input:-moz-placeholder {
  /* Firefox 18- */
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50; }

#user-register-form input:not(input[type="submit"]) {
  padding-left: 13px; }

#user-register-form .password-strength {
  margin-top: 0;
  display: none; }

#user-register-form .form-item label {
  font: normal normal 300 12px/9px Roboto;
  letter-spacing: -0.27px;
  color: #083c50; }

#user-register-form .form-item.form-type-password label {
  top: 20px; }

.view-table-of-contents .views-field-field-ac-lname svg {
  height: 12px;
  width: 12px;
  fill: #a9be3b;
  margin-right: 7px; }

.region-sidebar-first #block-block-6 svg {
  fill: #ffffff; }

.region-sidebar-first #block-block-6 .mailto__link {
  display: none; }

.searchForm__opened .logo-site-slogan {
  opacity: 0; }

#footer .block-simplenews label {
  display: none; }

#user-register-form .form-item.form-type-password label {
  display: none; }

.form-item input[type="text"]:focus,
.form-item input[type="text"].error:focus,
.form-item input[type="email"]:focus,
.form-item input[type="email"].error:focus,
.form-item input[type="password"]:focus,
.form-item input[type="password"].error:focus,
.form-item input[type="search"]:focus,
.form-item input[type="search"].error:focus,
.form-item input[type="tel"]:focus,
.form-item input[type="tel"].error:focus,
.form-item textarea:focus,
.form-item textarea.error:focus,
.form-item select:focus,
.form-item select.error:focus {
  border: none;
  outline: 2px solid #00b0ff; }

.page-node-1483 #messages {
  display: none; }

.paragraphs-item-text-with-indent {
  position: relative; }
  .paragraphs-item-text-with-indent:before {
    position: absolute;
    content: "";
    display: inline-block;
    background-image: url(../images/quotes.png);
    width: 21px;
    height: 15px;
    left: 0;
    top: 30px; }

@media (max-width: 768px) {
  .not-front #block-custom-solut-logo-svg-menu {
    margin-top: 50px; } }

.views-row-add-border .views-row {
  border: 1px solid #434343;
  border-radius: 3px; }

.views-row-add-border .views-row-1 {
  border: unset; }

.front .views-row-add-border .views-row-1 {
  border: 1px solid #434343;
  border-radius: 3px; }

.view-blog.view-display-id-page .views-row {
  margin-bottom: 10px; }

.view-articles.view-display-id-block_articles .nodeTeaser {
  padding: 24px 17px 0px; }
  .view-articles.view-display-id-block_articles .nodeTeaser .iconSvg-time {
    width: 16px;
    height: 16px; }
  .view-articles.view-display-id-block_articles .nodeTeaser .nodeTeaser__author {
    margin-bottom: 7px; }
  .view-articles.view-display-id-block_articles .nodeTeaser .nodeTeaser__author-name span {
    margin-left: 11px; }
  .view-articles.view-display-id-block_articles .nodeTeaser .nodeTeaser__author-info {
    padding-left: 0; }
  .view-articles.view-display-id-block_articles .nodeTeaser .nodeTeaser__author-image {
    display: none; }
  .view-articles.view-display-id-block_articles .nodeTeaser .iconSvg-user {
    display: block; }

.view-articles.view-display-id-block_1 .nodeTeaser {
  padding-top: 40px; }
  .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__readMore {
    text-align: center; }
  .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__author {
    justify-content: center; }
  .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__author-info {
    width: 150px; }
  .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__title {
    text-align: center;
    padding: 0 63px; }
    @media (max-width: 768px) {
      .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__title {
        padding: 0;
        text-align: left; } }
  .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__content {
    text-align: center;
    padding: 0 191px; }
    @media (max-width: 768px) {
      .view-articles.view-display-id-block_1 .nodeTeaser .nodeTeaser__content {
        padding: 0;
        text-align: left; } }

.view-articles.view-display-id-block_articles .view-content {
  display: flex;
  flex-wrap: wrap; }

.view-articles.view-display-id-block_articles .field-name-field-image {
  display: none; }

.view-articles.view-display-id-block_articles .views-row {
  margin-left: 16px;
  position: relative;
  height: 360px; }
  @media (--middle-viewport) {
    .view-articles.view-display-id-block_articles .views-row .nodeTeaser {
      padding: 15px 0; } }
  @media (--big-viewport) {
    .view-articles.view-display-id-block_articles .views-row {
      margin-left: 0;
      margin-bottom: 20px; } }
  @media (--middle-viewport) {
    .view-articles.view-display-id-block_articles .views-row {
      height: auto; } }

.view-articles.view-display-id-block_articles .views-row-1 {
  margin-left: 0; }

@media (min-width: 1136px) {
  .view-articles.view-display-id-block_articles .col-md-4 {
    flex: 0 0 32.333%; } }

.view-articles.view-display-id-page .views-row {
  margin-bottom: 10px; }

.view-articles.view-display-id-page .nodeTeaser__readMore {
  display: none; }

.view-articles .block__title {
  font: normal normal 300 40px/50px Roboto;
  color: #0c5982;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 31px;
  letter-spacing: 0; }
  @media (--middle-viewport) {
    .view-articles .block__title {
      font: normal normal 300 20px/30px Roboto;
      display: block;
      margin-bottom: 20px; } }
  .view-articles .block__title .more-link {
    margin-left: 10px; }
    .view-articles .block__title .more-link a {
      font: normal normal normal 14px/16px Arial;
      letter-spacing: 0px;
      color: #35b1c4;
      text-decoration: none; }
      .view-articles .block__title .more-link a:hover {
        text-decoration: underline; }

.blue-row {
  background: #0c5982;
  border: 1px solid #2bb1c5;
  color: #fff;
  border-radius: 3px;
  width: 100%; }
  .blue-row.views-row {
    height: auto;
    margin-left: 0;
    border: none; }
  .blue-row .nodeTeaser {
    border: 1px solid #2bb1c5;
    border-radius: 3px; }

.front .views-row-add-border .views-row-1.blue-row {
  border: unset; }
  .front .views-row-add-border .views-row-1.blue-row .nodeTeaser {
    border: unset;
    border-radius: unset; }

.view-resume .view-content {
  padding: 0 81px 0 14px; }
  @media (--middle-viewport) {
    .view-resume .view-content {
      padding: 0; } }

#block-views-resume-block {
  border-top: 1px dotted #7a7a7a;
  padding-top: 10px; }
  #block-views-resume-block > h2 {
    margin-bottom: 19px; }

.view-related-publications {
  border-top: 1px dotted #7a7a7a;
  border-bottom: 1px dotted #7a7a7a; }
  .view-related-publications .view-header {
    padding-top: 11px;
    margin-bottom: 14px; }
  .view-related-publications .views-row {
    margin-bottom: 20px; }
    .view-related-publications .views-row a {
      font: normal normal normal 18px/26px Roboto;
      letter-spacing: -0.5px;
      color: #1178af;
      text-decoration: none; }
      .view-related-publications .views-row a:hover {
        text-decoration: underline; }

.view-welcome-text .welomeTeaser__content {
  padding-bottom: 25px; }

.nodeTeaser {
  padding: 25px 17px; }
  @media (--middle-viewport) {
    .nodeTeaser {
      padding: 15px; } }
  .nodeTeaser__article .field-name-field-image {
    margin: 0 -30px; }
    .nodeTeaser__article .field-name-field-image img {
      display: block;
      width: 100%; }
  .nodeTeaser__article .iconSvg-time {
    width: 12px;
    height: 12px; }
  .nodeTeaser__blog {
    padding: 21px 30px 0px; }
    .nodeTeaser__blog .nodeTeaser__author {
      display: flex; }
    .nodeTeaser__blog .nodeTeaser__author-image {
      display: block; }
    .nodeTeaser__blog .nodeTeaser__author-info {
      padding-left: 19px; }
    .nodeTeaser__blog .nodeTeaser__content {
      margin-bottom: 27px; }
    .nodeTeaser__blog .iconSvg-user {
      display: none; }
    .nodeTeaser__blog .iconSvg-time {
      width: 12px;
      height: 12px;
      margin-left: 2px; }
    .nodeTeaser__blog .nodeTeaser__author {
      margin-bottom: 9px; }
    .nodeTeaser__blog .nodeTeaser__author-name span {
      margin-left: 0; }
    .nodeTeaser__blog .field-name-field-image {
      margin: 0 -30px; }
      .nodeTeaser__blog .field-name-field-image img {
        display: block;
        width: 100%; }
  .nodeTeaser__author {
    margin-bottom: 5px; }
    .nodeTeaser__author-image img {
      width: 40px;
      height: 40px;
      border-radius: 50%; }
    .nodeTeaser__author-name {
      font: normal normal bold 14px/16px Roboto;
      letter-spacing: -0.32px;
      color: #0c5982;
      display: inline-flex;
      width: 100%; }
      .nodeTeaser__author-name span {
        display: inline-block;
        margin-left: 8px; }
  .nodeTeaser__created {
    font: normal normal 300 12px/14px Roboto;
    color: #0c5982;
    display: inline-flex;
    width: 100%; }
    .nodeTeaser__created span {
      display: inline-block;
      margin-left: 8px; }
  .nodeTeaser__title {
    font: normal normal normal 18px/26px Roboto;
    letter-spacing: -0.5px;
    color: #0c5982;
    margin-bottom: 16px; }
    .nodeTeaser__title a {
      color: #0c5982; }
  .nodeTeaser__content {
    font: normal normal normal 14px/24px Roboto;
    letter-spacing: 0px;
    color: #7a7a7a; }
  .nodeTeaser__readMore {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0px 21px; }
    @media (--middle-viewport) {
      .nodeTeaser__readMore {
        padding: 0px;
        position: static; }
        .nodeTeaser__readMore .btn__blue {
          margin: 10px 0; } }

.nodeTeaser-blue {
  background-color: #0c5982;
  padding: 40px 25px 17px; }

.views-row-1 .nodeTeaser {
  background-color: #0c5982; }
  .views-row-1 .nodeTeaser .field-name-field-image {
    display: none; }
  .views-row-1 .nodeTeaser .nodeTeaser__readMore {
    position: static; }
  .views-row-1 .nodeTeaser .nodeTeaser__title {
    color: #fff;
    font: normal normal normal 40px/50px Roboto;
    text-align: left;
    padding: 0; }
    @media (--middle-viewport) {
      .views-row-1 .nodeTeaser .nodeTeaser__title {
        font: normal normal normal 20px/30px Roboto; } }
    .views-row-1 .nodeTeaser .nodeTeaser__title a {
      color: #fff; }
      .views-row-1 .nodeTeaser .nodeTeaser__title a b {
        font-weight: 400; }
  .views-row-1 .nodeTeaser .nodeTeaser__content {
    font: italic normal normal 18px/24px Roboto;
    color: #fff;
    text-align: left;
    padding: 0;
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      .views-row-1 .nodeTeaser .nodeTeaser__content .tex2jax {
        max-height: 76px;
        overflow: hidden; } }
    .views-row-1 .nodeTeaser .nodeTeaser__content p {
      color: #fff; }
      .views-row-1 .nodeTeaser .nodeTeaser__content p:last-child {
        margin-bottom: 0; }
    @media (--middle-viewport) {
      .views-row-1 .nodeTeaser .nodeTeaser__content {
        padding: 0;
        text-align: left; } }
  .views-row-1 .nodeTeaser .nodeTeaser__author {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px; }
  .views-row-1 .nodeTeaser .nodeTeaser__title {
    margin-bottom: 23px; }
    @media (--middle-viewport) {
      .views-row-1 .nodeTeaser .nodeTeaser__title {
        padding: 0;
        text-align: left; } }
  @media (--middle-viewport) {
    .views-row-1 .nodeTeaser .nodeTeaser__readMore {
      padding: 0; } }
  .views-row-1 .nodeTeaser .nodeTeaser__author-image {
    display: block;
    margin-left: 9px; }
    @media (--middle-viewport) {
      .views-row-1 .nodeTeaser .nodeTeaser__author-image {
        text-align: left;
        margin-left: 0; } }
  .views-row-1 .nodeTeaser .nodeTeaser__author-info {
    width: 50%;
    padding-left: 21px;
    transform: translateY(-2px); }
  .views-row-1 .nodeTeaser .nodeTeaser__created {
    transform: translateY(-7px);
    color: #fff; }
  .views-row-1 .nodeTeaser .iconSvg-time {
    width: 12px;
    height: 12px; }
  .views-row-1 .nodeTeaser .nodeTeaser__author-name {
    color: #fff; }
    .views-row-1 .nodeTeaser .nodeTeaser__author-name span {
      margin-left: 0; }
    .views-row-1 .nodeTeaser .nodeTeaser__author-name a {
      color: #fff; }
    .views-row-1 .nodeTeaser .nodeTeaser__author-name .iconSvg-user {
      display: none; }
  .views-row-1 .nodeTeaser.nodeTeaser__blogLong {
    padding-top: 26px;
    padding-left: 30px; }
    .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__author-image {
      width: 40px; }
    .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__title {
      padding: 0;
      text-align: left; }
    .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__content {
      padding: 0;
      text-align: left; }
    .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__author-image {
      display: block; }

.front .view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__author-name {
  color: #0c5982; }

.front .views-row-1 .nodeTeaser.nodeTeaser__blogLong {
  padding-top: 40px;
  padding-bottom: 25px;
  border-radius: 5px; }

.front .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__title {
  padding: 0px 59px;
  text-align: center;
  margin-bottom: 73px; }
  @media (--middle-viewport) {
    .front .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__title {
      padding: 0;
      margin-bottom: 10px; } }

.front .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__content {
  padding: 0 222px;
  text-align: center;
  margin-bottom: 21px; }
  @media (--middle-viewport) {
    .front .views-row-1 .nodeTeaser.nodeTeaser__blogLong .nodeTeaser__content {
      padding: 0; } }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser {
  background: #fff; }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__author-name a {
  color: #0c5982; }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__created {
  color: #0c5982;
  transform: translateY(0px); }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__author-name .iconSvg-user {
  display: block; }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__title {
  font: normal normal normal 18px/26px Roboto; }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__title a {
  color: #0c5982;
  font: normal normal normal 18px/26px Roboto; }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__content p {
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #7a7a7a; }

.front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__readMore {
  position: absolute; }
  @media (--middle-viewport) {
    .front .view-articles.view-display-id-block_articles .views-row-1 .nodeTeaser .nodeTeaser__readMore {
      position: static; } }

@media (--middle-viewport) {
  body #block-views-articles-block-articles .nodeTeaser__blog {
    height: auto; } }

.iconSvg {
  display: inline-block; }
  .iconSvg-user {
    fill: #a9be3b;
    width: 16px;
    height: 16px; }
  .iconSvg-time {
    fill: #a9be3b;
    width: 16px;
    height: 16px; }
  .iconSvg svg {
    width: 100%;
    height: 100%; }

.btn__blue {
  background: transparent;
  border: 1px solid #0c5982;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 3px;
  text-transform: inherit;
  font-size: 14px;
  color: #026fc2;
  transition: all 0.3s ease; }
  .btn__blue:hover {
    color: #fff;
    background: #a9be3b;
    border-color: #a9be3b; }

.btn__white {
  background: transparent;
  border: 1px solid #fff;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 3px;
  text-transform: inherit;
  font-size: 14px;
  color: #fff;
  transition: all 0.3s ease; }
  .btn__white:hover {
    color: #fff;
    background: #a9be3b;
    border-color: #a9be3b; }

.btn__tr {
  background: transparent;
  border: 1px solid #0c5982;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 3px;
  text-transform: inherit;
  font-size: 14px;
  color: #0c5982;
  transition: all 0.3s ease; }
  .btn__tr:hover {
    background-color: #0c5982;
    color: #fff; }

.header-wrapper {
  background: #557323; }

.menu-wrapper {
  background: #f1ecd7; }

.footer-wrapper {
  background: #f1ecd7; }

@media only screen and (min-width: 1256px) {
  .container {
    width: 1136px; }
  .one-sidebar .container {
    width: 1136px; } }

.svg-logo {
  fill: #a9be3b; }

.front .bllue {
  color: #fff; }

.front #block-menu-menu-blog > h2 a {
  color: #fff; }
  .front #block-menu-menu-blog > h2 a:hover {
    color: #a9be3b; }

.front .page-header {
  position: relative; }
  .front .page-header:after {
    content: "";
    display: block;
    position: absolute;
    height: 19px;
    width: 100%;
    left: 0;
    bottom: -19px;
    background-color: #a9be3b; }
  .front .page-header .front__scroll {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 49%;
    transform: translateX(-50%); }
    .front .page-header .front__scroll span {
      margin-left: 10px;
      color: #869884; }
    .front .page-header .front__scroll .a {
      opacity: 0.7; }
    .front .page-header .front__scroll .b,
    .front .page-header .front__scroll .e {
      fill: none; }
    .front .page-header .front__scroll .b {
      stroke: #fff;
      stroke-width: 2px; }
    .front .page-header .front__scroll .c {
      fill: #fff; }
    .front .page-header .front__scroll .d {
      stroke: none; }
  .front .page-header .region-sidebar-first {
    height: calc(100vh - 125px);
    position: relative;
    padding-left: 0; }
    @media (--middle-viewport) {
      .front .page-header .region-sidebar-first {
        height: auto; } }

.front .region-sidebar-first {
  padding-top: 88px; }

.front div#block-system-main {
  margin-top: 84px; }
  @media (--middle-viewport) {
    .front div#block-system-main {
      margin-top: 20px; } }

.front #block-block-6 .mailto__link {
  display: none; }

@media (min-width: 768px) {
  .front #block-custom-solut-logo-svg-menu,
  .front #block-menu-menu-menu-in-sidebar,
  .front #burger_toggle {
    display: none; }
  .front #sidebar-first {
    position: static;
    transform: translate3d(0, 0, 0);
    height: auto;
    background-color: transparent; } }

#footer {
  background: transparent;
  box-shadow: none; }
  #footer .inner {
    border-top: 1px dotted #7a7a7a;
    padding-top: 23px;
    position: relative; }
  #footer .form-item {
    margin-top: 11px;
    margin-bottom: 0; }
  #footer .form-text {
    border: 1px solid #d1dbe3;
    padding: 5px 16px;
    border-radius: 5px;
    height: 60px; }
    @media (--middle-viewport) {
      #footer .form-text {
        box-sizing: border-box; } }
  #footer form {
    width: 444px;
    position: relative; }
    @media (--middle-viewport) {
      #footer form {
        width: 100%; } }
  #footer .form-submit {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 120px;
    padding: 0 10px;
    z-index: 2; }

#block-block-7 {
  width: 100%; }

.block-simplenews {
  margin-bottom: 21px; }

.subscribe__title {
  font: normal normal normal 18px/26px Roboto;
  letter-spacing: -0.5px;
  color: #0c5982; }
  .subscribe__title span {
    font: italic normal normal 14px/24px Roboto;
    letter-spacing: 0px;
    color: #7a7a7a; }

.soc-links-wrapper-mail {
  font: normal normal normal 14px/24px Roboto;
  color: #2bb1c5;
  margin-left: 18px; }

.soc-links-wrapper a {
  margin-left: 6px; }
  .soc-links-wrapper a:first-child {
    margin-left: 0; }

.soc-links-wrapper,
.soc-links-wrapper-mail {
  display: inline-block;
  vertical-align: top; }

#block-custom-solut-logo-svg-bottom {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 66px; }
  #block-custom-solut-logo-svg-bottom svg {
    width: 50px;
    height: 50px; }
  @media (--middle-viewport) {
    #block-custom-solut-logo-svg-bottom {
      position: absolute;
      right: 15px;
      bottom: 0;
      margin-bottom: 0;
      top: auto; } }

.region-footer-content .menu li:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 21px;
  width: 1px;
  background: #2bb1c5; }

.region-footer-content .menu li a {
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: -0.5px;
  color: #2bb1c5;
  padding: 0 18px; }

@media (max-width: 1136px) {
  .region-footer-content .menu li.first a {
    padding-left: 0; } }

@media (max-width: 394px) {
  .region-footer-content .menu li.last {
    width: 100%; } }

.region-footer-content .menu li.last:after {
  display: none; }

@media (max-width: 394px) {
  .region-footer-content .menu li.last a {
    padding-left: 0; } }

.region-footer-content .block-menu {
  padding-top: 17px;
  margin-bottom: 26px; }
  @media (--middle-viewport) {
    .region-footer-content .block-menu {
      padding-top: 0; } }

#block-block-9 {
  font: italic normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #bfbfbf;
  text-align: right;
  width: 100%; }
  @media (--big-viewport) {
    #block-block-9 {
      text-align: left;
      margin-bottom: 71px; } }

.logged-in #footer .form-submit {
  position: static;
  margin-left: 0;
  margin-bottom: 0; }

.front .page-header {
  height: 100vh; }

.front .page-header-bg {
  background: url(../images/main-header-bg.png) no-repeat;
  background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 0; }

.front .page-header-bg-name {
  background: url(../images/front-name.png) no-repeat;
  background-size: auto;
  background-position: 44% 80%;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 0; }
  @media (--middle-viewport) {
    .front .page-header-bg-name {
      display: none; } }

#navbar {
  padding: 16px 0 9px;
  height: auto; }
  #navbar .region a {
    color: #fff;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0.19px;
    text-transform: uppercase; }

#block-locale-language ul.language-switcher-locale-url {
  display: flex; }
  #block-locale-language ul.language-switcher-locale-url li a {
    color: #e4e4e4; }
  #block-locale-language ul.language-switcher-locale-url li.last {
    padding-left: 3px; }
    #block-locale-language ul.language-switcher-locale-url li.last:before {
      content: "";
      display: inline-block;
      width: 1px;
      background-color: #e4e4e4;
      height: 9px;
      margin-right: 5px; }
  #block-locale-language ul.language-switcher-locale-url li.active a {
    color: #fff; }

#block-block-2 {
  opacity: 0.85; }
  #block-block-2 svg {
    width: 7px;
    fill: #fff;
    height: 8px;
    margin-left: 5px; }
  #block-block-2 a {
    padding: 0 0px 2px;
    position: relative; }
    #block-block-2 a:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      background: #fff;
      bottom: -3px;
      transition: width 0.3s ease; }
    #block-block-2 a:hover:before {
      width: 0; }

.i18n-ru #block-custom-solut-logo-svg {
  width: 294px; }

#block-custom-solut-logo-svg {
  width: 308px;
  position: relative;
  z-index: 2; }
  @media (max-width: 768px) {
    #block-custom-solut-logo-svg {
      width: 260px; } }
  #block-custom-solut-logo-svg > .content {
    display: flex;
    flex-wrap: wrap; }
  #block-custom-solut-logo-svg .logo-image {
    width: 51px;
    height: 51px; }
    #block-custom-solut-logo-svg .logo-image svg {
      width: 100%;
      height: 100%; }
  #block-custom-solut-logo-svg .logo-site-name {
    color: #fff;
    font-size: 29px;
    text-transform: uppercase;
    font-weight: 700;
    width: 220px;
    line-height: 0.9;
    padding-left: 6px;
    letter-spacing: 1px; }
  #block-custom-solut-logo-svg .logo-site-slogan {
    font-size: 12px;
    color: #e4e4e4;
    letter-spacing: 0.19px;
    text-transform: uppercase;
    margin-top: 10px; }
    @media (max-width: 768px) {
      #block-custom-solut-logo-svg .logo-site-slogan {
        font-size: 10px; } }

.front #block-search-form .search-form-trigger svg {
  fill: #ffffff;
  position: relative;
  z-index: 2; }

.front .searchForm__opened #block-search-form .search-form-trigger svg {
  z-index: 0; }

#block-search-form {
  width: 25px;
  height: 25px;
  overflow: hidden;
  position: static; }
  #block-search-form .search-form-trigger {
    cursor: pointer; }
    #block-search-form .search-form-trigger svg {
      width: 16px;
      height: 16px;
      fill: #434343;
      transition: all 0.3s ease; }
      #block-search-form .search-form-trigger svg:hover {
        fill: #6a6a6a; }
  #block-search-form .icon-left::before {
    content: ""; }

@media (min-width: 1133px) {
  .front.i18n-ru #block-system-main-menu {
    margin-left: 24px; } }

#block-system-main-menu {
  margin-left: 0px;
  position: relative;
  flex: 1;
  z-index: 2; }
  @media (--middle-viewport) {
    #block-system-main-menu {
      display: none; } }
  #block-system-main-menu .menu li a {
    position: relative;
    transition: all 0.3s ease;
    padding: 0 25px; }
    @media (max-width: 1134px) {
      #block-system-main-menu .menu li a {
        padding: 0 20px; } }
    #block-system-main-menu .menu li a:before {
      content: "\f061";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 10px;
      transform: rotate(-90deg);
      display: inline-block;
      position: absolute;
      bottom: -18px;
      left: 45%;
      color: transparent;
      transition: all 0.3s ease; }
    #block-system-main-menu .menu li a:hover {
      color: #a9be3b;
      text-decoration: underline; }
      #block-system-main-menu .menu li a:hover:before {
        color: #a9be3b; }

#block-block-6 {
  position: absolute;
  bottom: 25px;
  left: 0; }

.soc-links-wrapper svg {
  width: 20px;
  height: 20px;
  fill: #d8d9dd; }

.soc-links-wrapper a {
  display: inline-block; }

#block-custom-solut-logo-svg-menu {
  width: 50px;
  height: 50px;
  margin-left: 22px;
  margin-bottom: 29px; }
  #block-custom-solut-logo-svg-menu svg,
  #block-custom-solut-logo-svg-menu div {
    width: 100%;
    height: 100%; }

#block-menu-menu-menu-in-sidebar .menu {
  display: block; }
  #block-menu-menu-menu-in-sidebar .menu li {
    display: block; }
    #block-menu-menu-menu-in-sidebar .menu li a {
      padding: 0;
      color: #fff;
      font: normal normal normal 16px/16px Roboto;
      letter-spacing: 0.26px; }
      #block-menu-menu-menu-in-sidebar .menu li a:hover {
        background-color: transparent;
        color: #a9be3b;
        text-decoration: underline; }

#block-search-form > .content {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 3;
  background: rgba(12, 89, 130, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, -100%, 0); }

#block-search-form .form__close {
  position: absolute;
  right: 24px;
  top: 18px; }

#block-search-form .form_wrapper {
  width: 877px;
  position: relative;
  padding-left: 40px;
  padding-right: 40px; }

#block-search-form #form__close {
  width: 29px;
  height: 29px;
  fill: #2699fb;
  cursor: pointer;
  transition: all 0.3s ease; }
  #block-search-form #form__close:hover {
    fill: #ffffff; }

#block-search-form form {
  max-width: 100%; }
  #block-search-form form .form-item {
    height: auto; }
    #block-search-form form .form-item:before {
      margin: 0;
      padding: 0;
      width: 1px;
      height: auto;
      background: #2699fb;
      position: absolute;
      height: 34px;
      left: 0;
      top: 10px; }

#block-search-form form .form-item input[type="text"] {
  border-radius: 0;
  border-top: none;
  border-right: 0;
  border-left: 0;
  font: normal normal normal 40px/50px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.2; }
  #block-search-form form .form-item input[type="text"]::placeholder {
    font: normal normal normal 40px/50px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.2; }
    @media (--small-viewport) {
      #block-search-form form .form-item input[type="text"]::placeholder {
        font-size: 32px; } }

.searchForm__opened div#block-custom-solut-logo-svg {
  z-index: 4; }

.searchForm__opened div#block-custom-solut-logo-svg .logo-site-name {
  color: #fff; }

.searchForm__opened div#block-system-main-menu {
  z-index: 4; }

.searchForm__opened div#block-system-main-menu .menu li a {
  color: #fff; }
  .searchForm__opened div#block-system-main-menu .menu li a:hover {
    color: #a9be3b; }
    .searchForm__opened div#block-system-main-menu .menu li a:hover:before {
      color: #a9be3b; }

.region-navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.region-header {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  position: static; }

.region-footer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column; }

.region-footer-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 134px;
  padding-top: 44px; }
  @media (--big-viewport) {
    .region-footer-content {
      padding-left: 15px;
      padding-top: 20px;
      flex-direction: column; } }
  @media (--middle-viewport) {
    .region-footer-content {
      position: relative;
      padding-left: 12px; } }

.menu {
  display: flex;
  flex-wrap: wrap; }
  .menu li a {
    font: normal normal 300 16px/16px Roboto;
    letter-spacing: 0.26px;
    color: #ffffff;
    padding: 0 22px;
    display: inline-block; }
    @media (--big-viewport) {
      .menu li a {
        padding: 0 14px; } }

.region-header .menu li:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 21px;
  width: 1px;
  background: #ffffff; }

.region-header .menu li.last:after {
  display: none; }

article.node-article .field-name-field-related-publications .field-label,
article.node-blog-entry .field-name-field-related-publications .field-label {
  font: normal normal 300 34px/46px Roboto;
  letter-spacing: -0.94px;
  color: #0c5982; }

article.node-article .field-name-field-tags .field-label,
article.node-blog-entry .field-name-field-tags .field-label {
  font: normal normal 300 34px/46px Roboto;
  letter-spacing: -0.94px;
  color: #0c5982;
  margin-bottom: 8px; }

article.node-article .field-name-field-tags .field-items,
article.node-blog-entry .field-name-field-tags .field-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

article.node-article .field-name-field-tags .field-item,
article.node-blog-entry .field-name-field-tags .field-item {
  background: #1487c5;
  color: #fff;
  border: 1px solid transparent;
  font: normal normal normal 12px/12px Roboto;
  transition: all 0.3s ease;
  padding: 9px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px; }
  article.node-article .field-name-field-tags .field-item a,
  article.node-blog-entry .field-name-field-tags .field-item a {
    color: #fff; }
  article.node-article .field-name-field-tags .field-item:hover,
  article.node-blog-entry .field-name-field-tags .field-item:hover {
    color: #1487c5;
    border: 1px solid #1487c5;
    background: #fff; }
    article.node-article .field-name-field-tags .field-item:hover a,
    article.node-blog-entry .field-name-field-tags .field-item:hover a {
      color: #1487c5; }

.field-name-field-tags {
  margin-bottom: 27px; }

.welcomeTeaser {
  border-top: 1px dotted #7a7a7a;
  padding-top: 27px; }
  .welcomeTeaser .col-md-4 {
    position: relative; }
    .welcomeTeaser .col-md-4:last-child {
      padding-left: 0; }
      @media (--small-viewport) {
        .welcomeTeaser .col-md-4:last-child {
          padding-left: 12px; } }
    @media (min-width: 768px) {
      .welcomeTeaser .col-md-4 {
        height: 518px;
        overflow: hidden; } }
  .welcomeTeaser .link-read-more {
    position: absolute;
    bottom: 0px;
    font: normal normal medium 14px/16px Roboto; }
  .welcomeTeaser__middleCol {
    position: relative;
    padding-left: 0; }
    @media (--small-viewport) {
      .welcomeTeaser__middleCol {
        padding-left: 12px; } }
    .welcomeTeaser__middleCol p {
      color: #0c5982;
      margin-bottom: 13px;
      line-height: 1.1; }
    .welcomeTeaser__middleCol:after {
      content: "";
      display: block;
      width: 93%;
      position: absolute;
      bottom: 3px;
      left: 0;
      height: 2px;
      background-color: #a9be3b; }
      @media (--middle-viewport) {
        .welcomeTeaser__middleCol:after {
          display: none; } }
  @media (--small-viewport) {
    .welcomeTeaser p {
      margin-bottom: 0; } }
  .welcomeTeaser__middleText {
    font: normal normal normal 40px/50px Roboto;
    color: #0c5982;
    margin-bottom: 9px; }
  .welcomeTeaser__author {
    font: normal normal bold 14px/16px Roboto;
    color: #0c5982; }
    .welcomeTeaser__author .iconSvg-user {
      width: 15px;
      height: 15px;
      margin-right: 10px; }
      .welcomeTeaser__author .iconSvg-user svg {
        transform: translate(3px, 3px); }
  .welcomeTeaser__created {
    font: normal normal 300 12px/14px Roboto;
    color: #0c5982;
    position: absolute;
    bottom: 32px;
    left: -5px;
    width: 100%;
    padding-left: 9px; }
    .welcomeTeaser__created .iconSvg-time {
      margin-right: 10px; }
    .welcomeTeaser__created span:last-child {
      transform: translate(-4px, -5px);
      display: inline-block; }
    @media (--middle-viewport) {
      .welcomeTeaser__created {
        position: static;
        margin: 20px 0; } }
  .welcomeTeaser__content {
    font: normal normal normal 14px/24px Roboto;
    color: #7a7a7a; }

.not-front #block-locale-language ul.language-switcher-locale-url li a {
  color: #e4e4e4; }

.not-front #block-locale-language ul.language-switcher-locale-url li.active a {
  color: #0c5982; }

.not-front #block-menu-menu-blog h2 a {
  color: #fff; }
  .not-front #block-menu-menu-blog h2 a:hover {
    color: #deff00; }

.not-front #block-custom-solut-logo-svg .logo-site-name {
  color: #0c5982; }

.not-front #block-custom-solut-logo-svg .logo-site-slogan {
  color: #434343; }

.not-front .menu li:after {
  background: #0c5982; }

.not-front .menu li a {
  color: #0c5982; }

.not-front #block-block-2 a {
  color: #0c5982; }
  .not-front #block-block-2 a:before {
    background-color: #0c5982; }
  .not-front #block-block-2 a svg {
    fill: #0c5982; }

.not-front .region-header {
  justify-content: space-between; }

.not-front #block-system-main-menu {
  margin-right: 13px; }
  .not-front #block-system-main-menu .menu {
    justify-content: flex-end; }

.not-front .block-title {
  font: normal normal 300 34px/46px Roboto;
  letter-spacing: -0.94px;
  color: #0c5982; }

.not-front #block-block-6 {
  position: static; }

@media (max-width: 1135px) {
  .not-front #page-title {
    clear: both;
    font-size: 24px; } }

@media (max-width: 1185px) {
  .not-front .region-navbar {
    justify-content: flex-end; }
  .not-front #block-locale-language + #block-block-2 {
    margin-left: 20px; }
  .not-front .green-line::before {
    left: 0; }
  .not-front #header-content {
    margin-bottom: 15px; }
  .not-front #block-custom-solut-logo-svg .logo-site-name {
    width: 180px;
    font-size: 17px;
    line-height: 1.3; }
  .not-front #burger_toggle {
    left: 17px; } }

@media (max-width: 768px) {
  .not-front .region-content {
    padding-top: 15px; }
  .not-front #main {
    padding-top: 15px; } }

.nodeTeaser.nodeTeaser__resume {
  background: #f7fafc;
  color: #0c5982;
  padding: 26px 17px 25px;
  position: relative; }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__resume-left.col-md-3 {
    padding-left: 0;
    padding-right: 17px; }
    @media (--middle-viewport) {
      .nodeTeaser.nodeTeaser__resume .nodeTeaser__resume-left.col-md-3 {
        padding-right: 0; } }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__title a {
    font: normal normal normal 34px/40px Roboto;
    color: #0c5982; }
    .nodeTeaser.nodeTeaser__resume .nodeTeaser__title a b {
      display: block; }
  .nodeTeaser.nodeTeaser__resume .soc-links-wrapper {
    padding-left: 12px;
    margin-bottom: 7px; }
    @media (--middle-viewport) {
      .nodeTeaser.nodeTeaser__resume .soc-links-wrapper {
        padding-left: 0; } }
    .nodeTeaser.nodeTeaser__resume .soc-links-wrapper svg,
    .nodeTeaser.nodeTeaser__resume .soc-links-wrapper .a {
      fill: #a9be3b !important; }
    .nodeTeaser.nodeTeaser__resume .soc-links-wrapper a {
      margin-left: 6px; }
      .nodeTeaser.nodeTeaser__resume .soc-links-wrapper a:first-child {
        margin-left: 0; }
  .nodeTeaser.nodeTeaser__resume .wrapper-mail {
    padding-left: 15px;
    font: normal normal normal 14px/24px Roboto; }
    @media (--middle-viewport) {
      .nodeTeaser.nodeTeaser__resume .wrapper-mail {
        padding-left: 0; } }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__resume-right.col-md-9 {
    padding: 0;
    padding-top: 5px; }
  .nodeTeaser.nodeTeaser__resume .field-name-field-photo {
    margin-bottom: 21px; }
    .nodeTeaser.nodeTeaser__resume .field-name-field-photo img {
      display: block;
      width: 132px;
      height: 132px;
      border-radius: 50%; }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__title {
    font: normal normal bold 34px/40px Roboto; }
    @media (--middle-viewport) {
      .nodeTeaser.nodeTeaser__resume .nodeTeaser__title {
        font: normal normal bold 20px/20px Roboto;
        margin-bottom: 0; } }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__resumeDesc {
    font: normal normal bold 14px/24px Roboto;
    padding-top: 7px; }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__resumeShort {
    font: normal normal bold 14px/24px Roboto; }
  .nodeTeaser.nodeTeaser__resume .field-name-body {
    font: normal normal 300 14px/24px Roboto;
    color: #0c5982; }
    .nodeTeaser.nodeTeaser__resume .field-name-body a {
      color: #0c5982; }
  .nodeTeaser.nodeTeaser__resume .nodeTeaser__resume-top {
    margin: 0;
    margin-bottom: 9px; }

.nodeTeaser .link__more {
  position: absolute;
  right: 0;
  bottom: 0;
  position: absolute;
  right: 15px;
  bottom: 16px;
  font: 14px/16px Roboto;
  text-decoration: underline;
  font-weight: 500;
  text-decoration: underline; }
  .nodeTeaser .link__more:hover {
    text-decoration: none; }

.nodeFull__resume .field-name-body {
  font: normal normal 300 18px/24px Roboto;
  letter-spacing: 0px;
  color: #434343; }

.view-resume.view-display-id-block .view-content {
  padding: 0 0 0 14px; }
  @media (max-width: 768px) {
    .view-resume.view-display-id-block .view-content {
      padding: 0 10px; } }

.page-user #main > .row {
  width: 100%; }
  @media (--middle-viewport) {
    .page-user #main > .row {
      margin: 0; } }

.page-user #main .card-form {
  max-width: 100%;
  border-radius: 0;
  box-shadow: none; }

.page-user #main #block-system-main .content {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); }
  .page-user #main #block-system-main .content .messages {
    background: #FFFFFF;
    font-weight: lighter;
    width: 352px;
    margin: 0 auto;
    padding: 15px 0 0 0;
    text-align: center; }
    .page-user #main #block-system-main .content .messages ul {
      padding-left: 0; }
    @media (--middle-viewport) {
      .page-user #main #block-system-main .content .messages {
        width: 100%; } }
  .page-user #main #block-system-main .content .messages-error {
    color: #ff0000;
    padding-left: 0; }
  .page-user #main #block-system-main .content .messages-error::before {
    content: ""; }

.comment {
  background: #f7fafc 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  padding: 29px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 200px;
  margin-bottom: 20px; }
  @media (--middle-viewport) {
    .comment {
      height: auto;
      padding: 15px;
      flex-direction: column; } }
  .comment .links {
    display: flex; }
    .comment .links li {
      margin-left: 5px; }
      .comment .links li:first-child {
        margin-left: 0; }
  .comment img {
    width: 30px;
    height: 30px;
    border-radius: 50%; }
  .comment__left {
    width: 30px; }
    @media (--middle-viewport) {
      .comment__left {
        width: 100%; } }
  .comment__right {
    width: calc(100% - 30px);
    padding-left: 22px; }
    @media (--middle-viewport) {
      .comment__right {
        width: 100%;
        padding-left: 0; } }
  .comment__fullName {
    font: normal normal bold 14px/24px Roboto;
    color: #1487c5;
    padding-top: 3px;
    margin-bottom: 20px; }
  .comment__content {
    font: normal normal normal 14px/24px Roboto;
    color: #6b6c7e;
    padding-right: 85px; }
    @media (--middle-viewport) {
      .comment__content {
        padding-right: 0; } }

#comments {
  margin-top: 26px;
  border-bottom: 1px dashed #707070;
  margin-bottom: 20px; }
  #comments .title {
    margin-bottom: 16px; }

.node__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .node__bottom .links {
    font: normal normal 300 18px/24px Roboto;
    color: #1178af; }
    .node__bottom .links a {
      text-decoration: none; }
      .node__bottom .links a:hover {
        text-decoration: underline; }
  .node__bottom .view__count {
    font: normal normal normal 14px/21px Roboto;
    letter-spacing: 0px;
    color: #8d8d8d; }

.filter-help {
  float: none;
  padding: 0; }
  .filter-help a {
    padding-left: 0; }

#block-menu-menu-blog {
  border-left: 4px solid #a9be3b;
  margin-bottom: 50px; }
  #block-menu-menu-blog > h2 {
    letter-spacing: 0.38px;
    color: #ffffff;
    text-transform: uppercase;
    padding-left: 19px;
    margin-bottom: 2px;
    font-weight: bold; }
    #block-menu-menu-blog > h2:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f3be";
      font-size: 10px;
      margin-left: 4px; }
  #block-menu-menu-blog .menu li {
    padding: 12px 0px; }
    #block-menu-menu-blog .menu li.last {
      padding-bottom: 1px; }
    #block-menu-menu-blog .menu li a {
      text-decoration: none;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.34px;
      color: #ffffff;
      padding: 0 19px;
      position: relative; }
      #block-menu-menu-blog .menu li a:after {
        content: "\f061";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 10px;
        position: absolute;
        top: 50%;
        left: -23px;
        transform: translateY(-50%);
        color: transparent; }
      #block-menu-menu-blog .menu li a:before {
        content: "";
        display: block;
        position: absolute;
        width: 11px;
        height: 17px;
        background: transparent;
        transition: background ease 0.3s;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }
      #block-menu-menu-blog .menu li a:hover, #block-menu-menu-blog .menu li a.active {
        text-decoration: underline;
        color: #a9be3b;
        background: none; }
        #block-menu-menu-blog .menu li a:hover:before, #block-menu-menu-blog .menu li a.active:before {
          background: #a9be3b; }
        #block-menu-menu-blog .menu li a:hover:after, #block-menu-menu-blog .menu li a.active:after {
          color: #a9be3b; }

#sidebar-first {
  background: #a9be3b;
  height: 100vh;
  position: absolute;
  width: 352px;
  z-index: 9;
  min-height: 751px;
  transform: translate3d(-100%, 0, 0);
  left: 0; }
  @media (--middle-viewport) {
    #sidebar-first {
      width: 100%; } }

.region-sidebar-first {
  padding: 44px 38px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

#block-menu-menu-menu-in-sidebar {
  padding-left: 23px;
  border-left: 2px solid #fff; }
  #block-menu-menu-menu-in-sidebar .menu li {
    margin-bottom: 9px; }

#block-menu-menu-blog > h2 {
  font: normal normal bold 24px/16px Roboto;
  letter-spacing: 0.38px;
  color: #ffffff;
  font-weight: bold; }

#block-menu-menu-blog .menu li {
  width: 100%;
  display: block;
  padding: 12px 0px 13px; }
  #block-menu-menu-blog .menu li a {
    color: #fff; }
    #block-menu-menu-blog .menu li a:hover {
      color: #a9be3b; }

@media (min-width: 992px) {
  .one-sidebar #sidebar-first {
    order: 1;
    flex: 0 0 352px;
    width: 352px;
    margin-right: 0px; } }

@media (max-width: 768px) {
  .front #sidebar-first {
    top: 0;
    width: 100%; }
  .front .page-header .region-sidebar-first {
    padding-left: 16px;
    height: 100%; }
  .front .region-navbar {
    justify-content: flex-end; }
  .front #block-locale-language + #block-block-2 {
    margin-left: 20px; }
  .front .i18n-ru #block-custom-solut-logo-svg {
    width: 250px; }
  .front #block-custom-solut-logo-svg {
    width: 250px; } }
  @media (max-width: 768px) and (--small-viewport) {
    .front #block-custom-solut-logo-svg {
      width: 260px; } }

@media (max-width: 768px) {
  .front #block-custom-solut-logo-svg .logo-site-name {
    width: 180px;
    font-size: 17px;
    line-height: 1.3; }
  .front #block-block-6 {
    position: static; }
  .front .page-header-bg {
    background-color: #839581;
    background-image: none;
    display: none; }
  .front .page-header {
    background-color: #839581;
    box-shadow: 0px -3px 5px 5px #839581;
    height: auto;
    padding-bottom: 15px; }
  .front #block-custom-solut-logo-svg-menu {
    margin-left: 0;
    margin-bottom: 20px; }
  .front #burger_toggle {
    left: 24px; } }

@media (max-width: 567px) {
  .front #burger_toggle {
    left: 17px; } }

@media (min-width: 768px) {
  .not-front.sidebar__custom_style #burger_toggle {
    display: none; } }

@media (max-width: 768px) {
  .not-front.sidebar__custom_style #burger_toggle {
    left: 25px; }
  .not-front.sidebar__custom_style #sidebar-first {
    background: #b1c52b;
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 9;
    min-height: 751px;
    transform: translate3d(-100%, 0, 0);
    left: 0;
    top: 0; }
  .not-front.sidebar__custom_style .region-sidebar-first {
    padding-left: 16px; }
  .not-front.sidebar__custom_style #main > .row {
    margin: 0; }
  .not-front.sidebar__custom_style #block-menu-menu-blog {
    border-left: 4px solid #defd35; }
    .not-front.sidebar__custom_style #block-menu-menu-blog h2:after {
      color: #ffffff; }
    .not-front.sidebar__custom_style #block-menu-menu-blog h2 > a {
      color: #fff; }
      .not-front.sidebar__custom_style #block-menu-menu-blog h2 > a:hover {
        text-decoration: underline;
        color: #fff; }
    .not-front.sidebar__custom_style #block-menu-menu-blog .menu li a {
      color: #ffffff; }
      .not-front.sidebar__custom_style #block-menu-menu-blog .menu li a.active, .not-front.sidebar__custom_style #block-menu-menu-blog .menu li a:hover {
        color: #deff00; }
        .not-front.sidebar__custom_style #block-menu-menu-blog .menu li a.active:before, .not-front.sidebar__custom_style #block-menu-menu-blog .menu li a:hover:before {
          background: #deff00; } }

@media (max-width: 567px) {
  .not-front.sidebar__custom_style #burger_toggle {
    left: 17px; } }

@media (min-width: 960px) {
  .toolbar #sidebar-first {
    height: calc(100vh - 64px); }
  .toolbar #burger_toggle {
    top: 80px; } }

/* Оглавление в статье блога */
#block-views-table-of-contents-block {
  max-width: 300px; }

#block-views-table-of-contents-block h2 {
  text-align: left;
  font: normal normal 300 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982;
  opacity: 1;
  margin-bottom: 12px;
  margin-top: 30px; }

#block-views-table-of-contents-block a {
  text-align: left;
  text-decoration: none;
  font: normal normal 300 14px/26px Roboto;
  letter-spacing: -0.39px;
  color: #0c5982;
  opacity: 1; }
  #block-views-table-of-contents-block a:hover {
    text-decoration: underline; }

#block-views-table-of-contents-block a:before {
  content: "";
  background: url(../../src/svg/arrow-right.svg) no-repeat;
  background-size: cover;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 5px; }

/* Автор в сайдбаре на странице блога */
#block-views-table-of-contents-block-1 .views-field {
  display: inline-block;
  vertical-align: middle; }

#block-views-table-of-contents-block-1 .views-field-picture {
  margin-right: 10px; }

#block-views-table-of-contents-block-1 .name {
  text-align: left;
  font: normal normal bold 14px/16px Roboto;
  letter-spacing: -0.32px;
  color: #0c5982;
  opacity: 1;
  margin-bottom: 4px; }

.welcomeTeaser__author a,
.nodeTeaser__author-name a {
  color: #0c5982; }

#block-views-table-of-contents-block-1 .date {
  text-align: left;
  font: normal normal 300 12px/14px Roboto;
  letter-spacing: -0.27px;
  color: #0c5982;
  opacity: 1; }

/* ссылки в крошках */
.breadcrumb a {
  text-align: left;
  font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1; }

/* Статья */
.node-webform .field-name-body,
.node-page .field-name-body,
.node-blog-entry .field-name-body,
.node-article .field-name-body {
  text-align: left;
  font: normal normal 300 18px/24px Roboto;
  letter-spacing: 0px;
  color: #434343; }

.node-page .field-name-body ul,
.node-blog-entry .field-name-body ul,
.node-article .field-name-body ul {
  margin: 40px 0; }

.node-page .field-name-body ul > li,
.node-blog-entry .field-name-body ul > li,
.node-article .field-name-body ul > li {
  background: url("../../src/svg/dotts.svg") no-repeat;
  background-size: 54px 2px;
  background-position: 18px 12px;
  padding-left: 86px;
  text-align: left;
  font: italic normal 300 18px/34px Roboto;
  letter-spacing: -0.15px;
  color: #3e3e3e;
  opacity: 1;
  line-height: 20px;
  margin-bottom: 14px;
  list-style: none; }

.node-page .field-name-body ul > li:before,
.node-blog-entry .field-name-body ul > li:before,
.node-article .field-name-body ul > li:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 2px solid #3e3e3e;
  border-radius: 10px;
  position: relative;
  left: -86px;
  margin-right: -12px;
  top: 3px; }

.node-page .field-name-body ol,
.node-blog-entry .field-name-body ol,
.node-article .field-name-body ol {
  counter-reset: number;
  list-style-type: none;
  margin: 40px 0 35px; }

.node-page .field-name-body ol > li,
.node-blog-entry .field-name-body ol > li,
.node-article .field-name-body ol > li {
  background: url("../../src/svg/dotts.svg") no-repeat;
  background-size: 54px 2px;
  background-position: 20px 22px;
  padding-left: 92px;
  text-align: left;
  font: italic normal 300 18px/34px Roboto;
  letter-spacing: -0.15px;
  color: #3e3e3e;
  opacity: 1;
  line-height: 20px;
  margin-bottom: 8px;
  list-style: none; }

.node-page .field-name-body ol > li:before,
.node-blog-entry .field-name-body ol > li:before,
.node-article .field-name-body ol > li:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  position: relative;
  left: -92px;
  margin-right: -12px;
  counter-increment: number;
  content: counter(number) " ";
  font: normal normal normal 18px/34px Roboto; }

article .content a[href^="#"]:not([href="#"]):not(.btn) {
  border-bottom: unset; }

.node-page .field-name-body ol ul,
.node-blog-entry .field-name-body ol ul,
.node-article .field-name-body ol ul {
  margin-left: -60px; }

body {
  background-color: #fff; }

.node-page.view-mode-full .field-name-body h2,
.node-blog-entry .field-name-body h2,
.node-article .field-name-body h2 {
  margin-bottom: 10px; }

.node-blog-entry .field-name-body h3,
.node-article .field-name-body h3 {
  margin-bottom: 4px; }

.node-blog-entry .field-name-body h4,
.node-article .field-name-body h4 {
  margin-bottom: 8px;
  font-weight: 500; }

.node-blog-entry .field-name-body a:hover,
.node-article .field-name-body a:hover {
  text-decoration: underline; }

article .content a[href^="#"]:not([href="#"]):not(.btn) {
  text-decoration: none; }
  article .content a[href^="#"]:not([href="#"]):not(.btn):hover {
    text-decoration: underline; }

/* blockquote */
article .content blockquote {
  border-top: 1px dashed #707070;
  border-bottom: 1px dashed #707070;
  border-left: 0;
  padding-left: 88px;
  padding-top: 30px;
  background: url("../../src/svg/quote.svg") no-repeat 2px 37px;
  margin-bottom: 38px;
  padding-bottom: 33px; }

/* comments */
.comment {
  height: unset; }

.not-front footer#footer {
  background-color: #fff;
  padding-bottom: 30px; }

.view-related-publications {
  padding-bottom: 20px; }

article {
  position: relative; }

article .ya-share2 {
  position: absolute;
  right: 0;
  margin-top: 35px; }

.comment-title {
  margin-top: 20px; }

#burger_toggle {
  position: fixed; }
  @media (--middle-viewport) {
    #burger_toggle {
      position: absolute; } }

.not-front #block-menu-menu-blog .menu li a:hover:before,
#block-menu-menu-blog .menu li a.active:before {
  background-color: #fff; }

.not-front #block-menu-menu-blog {
  border-left: 4px solid #fff; }

.not-front #block-menu-menu-blog .menu li a:hover:after,
#block-menu-menu-blog .menu li a.active:after {
  color: #fff; }

.not-front #sidebar-first:not(.animate__slideInLeft) #block-block-6 {
  position: fixed;
  left: inherit;
  bottom: 40px; }

.pager .pager-item:first-child::before {
  display: none; }

.pager .pager-item:first-child a {
  padding-left: 0; }

.pager .pager-item a:hover {
  background: transparent; }

#sidebar-first {
  position: fixed; }

.page-node-1432 #page-title,
.page-node-916 #page-title,
.page-node-1443 #page-title,
.page-node-1365 #page-title,
.page-user #page-title,
.page-node-1483 #page-title {
  font-weight: 700;
  line-height: 1.1; }

.not-front.page-node-1483 #main > .row {
  width: 100%; }

.page-node-1483 #main #block-system-main .content {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding-top: 1px; }

.success-title {
  text-align: center;
  font: normal normal normal 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #b1c52b;
  opacity: 1;
  margin-top: 33px; }

.success-title-inner {
  display: inline-block;
  border-bottom: 1px dashed #b1c52b;
  padding-bottom: 13px;
  margin-bottom: 24px; }

.success-subtitle {
  text-align: center;
  font: normal normal 300 24px/26px Roboto;
  letter-spacing: -0.67px;
  color: #0c5982;
  opacity: 1;
  margin-bottom: 25px; }

.success-footer {
  text-align: center;
  font: normal normal 300 14px/18px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 50px; }

.success-footer a {
  text-decoration: underline; }

.not-front .nodeTeaser__readMore {
  display: none; }

.nodeTeaser__article .nodeTeaser__author-image,
.nodeTeaser__blog .nodeTeaser__author-image {
  display: block; }

.nodeTeaser__article .iconSvg-user,
.nodeTeaser__blog .iconSvg-user {
  display: none; }

.nodeTeaser__article .nodeTeaser__author-name span,
.nodeTeaser__blog .nodeTeaser__author-name span {
  margin-left: 0; }

.nodeTeaser__article .nodeTeaser__author,
.nodeTeaser__blog .nodeTeaser__author {
  display: flex; }

.nodeTeaser__article .nodeTeaser__author,
.nodeTeaser__blog .nodeTeaser__author {
  margin-bottom: 9px; }

.nodeTeaser__article .nodeTeaser__author-info,
.nodeTeaser__blog .nodeTeaser__author-info {
  padding-left: 19px; }

.locale-untranslated {
  text-decoration: none; }

#block-views-articles-block-articles .nodeTeaser__blog {
  height: 275px;
  overflow: hidden; }

.page-taxonomy h1#page-title {
  display: none; }

sup {
  vertical-align: super;
  font-size: smaller; }

sub {
  vertical-align: sub;
  font-size: smaller; }

.btn__blue {
  border: 1px solid #026fc2; }

.btn__blue:hover {
  box-shadow: 0px 11px 31px #b1b1b1; }

@media (min-width: 768px) {
  .nodeTeaser__article {
    padding: 21px 30px 0px; } }

.taxonomy__page__tag .nodeTeaser__article,
.taxonomy__page__tag .nodeTeaser__blog {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-radius: 3px; }
  .taxonomy__page__tag .nodeTeaser__article p:last-child,
  .taxonomy__page__tag .nodeTeaser__blog p:last-child {
    margin-bottom: 0; }

.page-taxonomy-term.sidebar__custom_style
.nodeTeaser__article
.page-taxonomy-term.sidebar__custom_style
.nodeTeaser__blog {
  border: none;
  margin-bottom: 0px;
  border-radius: 0px; }

#main > .row {
  width: 100%; }
  @media (--middle-viewport) {
    #main > .row {
      margin: 0; } }

.webform-client-form-1 {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px;
  border-top: 4px solid #a9be3b;
  margin-top: 49px; }

.webform-client-form-1365 .webform-component--imya,
.webform-client-form-1365 .webform-component--name {
  width: 332px;
  display: inline-block; }

@media (max-width: 1136px) {
  .webform-client-form-1365 .webform-component--imya,
  .webform-client-form-1365 .webform-component--name {
    width: 100%; } }

.webform-client-form-1365 {
  position: relative;
  padding: 24px 22px 27px;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 0px 0px 7px 7px;
  border-top: 4px solid #b1c52b;
  margin-top: 49px; }

.webform-client-form-1365 .form-actions input.form-submit,
.webform-client-form-1 .form-actions input.form-submit {
  background: #1487c5 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000033; }

.webform-client-form-1365 .webform-component--e-mail,
.webform-client-form-1365 .webform-component--contact-info {
  width: 310px;
  display: inline-block;
  margin-left: 10px; }

@media (max-width: 1136px) {
  .webform-client-form-1365 .webform-component--e-mail,
  .webform-client-form-1365 .webform-component--contact-info {
    width: 100%;
    margin-left: 0; } }

figcaption {
  text-align: left;
  font: italic normal 300 15px/21px Roboto;
  letter-spacing: 0px;
  color: #54585c; }

#user-register-form input:not(input[type="submit"])::placeholder {
  line-height: 15px; }

#block-views-articles-block-articles
.views-row-1
.nodeTeaser
.nodeTeaser__content
.tex2jax {
  overflow: hidden; }

#block-menu-menu-blog {
  max-width: 350px; }

/* table */
.node-blog-entry .field-name-body table,
.node-article .field-name-body table {
  margin-bottom: 38px; }
  .node-blog-entry .field-name-body table thead th,
  .node-article .field-name-body table thead th {
    text-align: center;
    font: italic 500 15px/21px Roboto;
    letter-spacing: 0px;
    color: #1178af;
    opacity: 1;
    line-height: 27px; }
    .node-blog-entry .field-name-body table thead th:first-child,
    .node-article .field-name-body table thead th:first-child {
      padding-left: 0;
      text-align: left; }
    .node-blog-entry .field-name-body table thead th:last-child,
    .node-article .field-name-body table thead th:last-child {
      padding-right: 0;
      text-align: right; }
  .node-blog-entry .field-name-body table tbody tr td,
  .node-article .field-name-body table tbody tr td {
    border-top: 1px dashed #3e3e3e;
    font: normal normal 400 18px/21px Roboto;
    letter-spacing: 0px;
    color: #2a2f34; }
    .node-blog-entry .field-name-body table tbody tr td:first-child,
    .node-article .field-name-body table tbody tr td:first-child {
      font: normal normal 500 18px/21px Roboto; }
  .node-blog-entry .field-name-body table tbody tr:first-child td,
  .node-article .field-name-body table tbody tr:first-child td {
    border-top: 1px solid #3e3e3e; }

.node-blog-entry .field-name-body table.complicated thead,
.node-article .field-name-body table.complicated thead {
  border-top: 1px solid #000; }

.node-blog-entry .field-name-body table.complicated td, .node-blog-entry .field-name-body table.complicated th,
.node-article .field-name-body table.complicated td,
.node-article .field-name-body table.complicated th {
  vertical-align: middle; }

.node-blog-entry .field-name-body table.complicated th,
.node-article .field-name-body table.complicated th {
  background: #eef2f4;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #2a2f34;
  vertical-align: middle;
  padding: 28px 23px;
  text-align: center;
  border-right: 1px solid #000;
  border-top: 1px dashed #000; }
  .node-blog-entry .field-name-body table.complicated th:last-child,
  .node-article .field-name-body table.complicated th:last-child {
    padding: 0;
    text-align: center;
    border-right: 0; }
  .node-blog-entry .field-name-body table.complicated th:first-child,
  .node-article .field-name-body table.complicated th:first-child {
    border-right: 1px solid #000; }

.node-blog-entry .field-name-body table.complicated tr:first-child th,
.node-article .field-name-body table.complicated tr:first-child th {
  border-top: 1px solid #000; }

.node-blog-entry .field-name-body table.complicated td:last-child,
.node-article .field-name-body table.complicated td:last-child {
  text-align: center; }

.node-blog-entry .field-name-body table.complicated [rowspan],
.node-article .field-name-body table.complicated [rowspan] {
  border-left: 1px solid #000; }
  .node-blog-entry .field-name-body table.complicated [rowspan]:first-child,
  .node-article .field-name-body table.complicated [rowspan]:first-child {
    border-left: 0; }

@media (max-width: 767px) {
  .node-blog-entry .field-name-body .table-responsive tr,
  .node-blog-entry .field-name-body .table-responsive tr:first-child,
  .node-blog-entry .field-name-body .table-responsive tr:last-child,
  .node-article .field-name-body .table-responsive tr,
  .node-article .field-name-body .table-responsive tr:first-child,
  .node-article .field-name-body .table-responsive tr:last-child {
    margin-bottom: 30px; }
    .node-blog-entry .field-name-body .table-responsive tr td,
    .node-blog-entry .field-name-body .table-responsive tr:first-child td,
    .node-blog-entry .field-name-body .table-responsive tr:last-child td,
    .node-article .field-name-body .table-responsive tr td,
    .node-article .field-name-body .table-responsive tr:first-child td,
    .node-article .field-name-body .table-responsive tr:last-child td {
      text-align: center;
      border: 1px solid black;
      border-bottom: 0; }
      .node-blog-entry .field-name-body .table-responsive tr td:before,
      .node-blog-entry .field-name-body .table-responsive tr:first-child td:before,
      .node-blog-entry .field-name-body .table-responsive tr:last-child td:before,
      .node-article .field-name-body .table-responsive tr td:before,
      .node-article .field-name-body .table-responsive tr:first-child td:before,
      .node-article .field-name-body .table-responsive tr:last-child td:before {
        content: none; }
      .node-blog-entry .field-name-body .table-responsive tr td:last-child,
      .node-blog-entry .field-name-body .table-responsive tr:first-child td:last-child,
      .node-blog-entry .field-name-body .table-responsive tr:last-child td:last-child,
      .node-article .field-name-body .table-responsive tr td:last-child,
      .node-article .field-name-body .table-responsive tr:first-child td:last-child,
      .node-article .field-name-body .table-responsive tr:last-child td:last-child {
        text-align: center;
        border-bottom: 1px solid black; }
      .node-blog-entry .field-name-body .table-responsive tr td:first-child,
      .node-blog-entry .field-name-body .table-responsive tr:first-child td:first-child,
      .node-blog-entry .field-name-body .table-responsive tr:last-child td:first-child,
      .node-article .field-name-body .table-responsive tr td:first-child,
      .node-article .field-name-body .table-responsive tr:first-child td:first-child,
      .node-article .field-name-body .table-responsive tr:last-child td:first-child {
        width: auto; } }

.math-inline {
  display: inline; }
  .math-inline *,
  .math-inline .MathJax_Display {
    display: inline-block !important;
    width: auto;
    text-align: center; }
  .math-inline .MathJax_Preview,
  .math-inline script {
    display: none !important; }

.MathJax_Display {
  overflow-y: hidden;
  overflow-x: auto; }
