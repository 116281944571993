.region-navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.region-header {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  position: static;
}
.region-footer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
}

.region-footer-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 134px;
  padding-top: 44px;
  @media (--big-viewport) {
    padding-left: 15px;
    padding-top: 20px;
    flex-direction: column;
  }
  @media (--middle-viewport) {
    position: relative;
    padding-left: 12px;
  }
}
