#block-locale-language {
  ul.language-switcher-locale-url {
    display: flex;
    li {
      a {
        color: #e4e4e4;
      }
      &.last {
        padding-left: 3px;
        &:before {
          content: "";
          display: inline-block;
          width: 1px;
          background-color: #e4e4e4;
          height: 9px;
          margin-right: 5px;
        }
      }
      &.active {
        a {
          color: #fff;
        }
      }
    }
  }
}

#block-block-2 {
  opacity: 0.85;
  svg {
    width: 7px;
    fill: #fff;
    height: 8px;
    margin-left: 5px;
  }
  a {
    padding: 0 0px 2px;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      background: #fff;
      bottom: -3px;
      transition: width 0.3s ease;
    }
    &:hover {
      &:before {
        width: 0;
      }
    }
  }
}
.i18n-ru {
  #block-custom-solut-logo-svg {
    width: 294px;
  }
}
#block-custom-solut-logo-svg {
  width: 308px;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    width: 260px;
  }
  & > .content {
    display: flex;
    flex-wrap: wrap;
  }
  .logo-image {
    width: 51px;
    height: 51px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .logo-site-name {
    color: #fff;
    font-size: 29px;
    text-transform: uppercase;
    font-weight: 700;
    width: 220px;
    line-height: 0.9;
    padding-left: 6px;
    letter-spacing: 1px;
  }
  .logo-site-slogan {
    font-size: 12px;
    color: #e4e4e4;
    letter-spacing: 0.19px;
    text-transform: uppercase;
    margin-top: 10px;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.front {
  #block-search-form {
    .search-form-trigger {
      svg {
        fill: #ffffff;
        position: relative;
        z-index: 2;
      }
    }
  }
}
.front {
  .searchForm__opened {
    #block-search-form {
      .search-form-trigger {
        svg {
          z-index: 0;
        }
      }
    }
  }
}
#block-search-form {
  width: 25px;
  height: 25px;
  overflow: hidden;
  position: static;
  .search-form-trigger {
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      fill: $mainColor;
      @include simpleTransition;
      &:hover {
        fill: $mainColorHover;
      }
    }
  }
  .icon-left::before {
    content: "";
  }
}
.front {
  &.i18n-ru {
    #block-system-main-menu {
      @media (min-width: 1133px) {
        margin-left: 24px;
      }
    }
  }
}
#block-system-main-menu {
  margin-left: 0px;
  position: relative;
  flex: 1;
  z-index: 2;
  @media (--middle-viewport) {
    display: none;
  }
  .menu {
    li {
      a {
        position: relative;
        transition: all 0.3s ease;
        padding: 0 25px;
        @media (max-width: 1134px) {
          padding: 0 20px;
        }
        &:before {
          content: "\f061";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 10px;
          transform: rotate(-90deg);
          display: inline-block;
          position: absolute;
          bottom: -18px;
          left: 45%;
          color: transparent;
          transition: all 0.3s ease;
        }
        &:hover {
          color: $green-custom;
          text-decoration: underline;
          &:before {
            color: $green-custom;
          }
        }
      }
    }
  }
}

#block-block-6 {
  position: absolute;
  bottom: 25px;
  left: 0;
}
.soc-links-wrapper {
  svg {
    width: 20px;
    height: 20px;
    fill: #d8d9dd;
  }
  a {
    display: inline-block;
  }
}

#block-custom-solut-logo-svg-menu {
  width: 50px;
  height: 50px;
  margin-left: 22px;
  margin-bottom: 29px;
  svg,
  div {
    width: 100%;
    height: 100%;
  }
}

#block-menu-menu-menu-in-sidebar {
  .menu {
    display: block;
    li {
      display: block;
      a {
        padding: 0;
        color: #fff;
        font: normal normal normal 16px/16px Roboto;
        letter-spacing: 0.26px;
        &:hover {
          background-color: transparent;
          color: $green-custom;
          text-decoration: underline;
        }
      }
    }
  }
}

#block-search-form {
  & > .content {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 3;
    background: rgba(12, 89, 130, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, -100%, 0);
  }
  .form__close {
    position: absolute;
    right: 24px;
    top: 18px;
  }
  .form_wrapper {
    width: 877px;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
  }
  #form__close {
    width: 29px;
    height: 29px;
    fill: #2699fb;
    cursor: pointer;
    @include simpleTransition;
    &:hover {
      fill: #ffffff;
    }
  }
  form {
    max-width: 100%;
    .form-item {
      height: auto;
      &:before {
        margin: 0;
        padding: 0;
        width: 1px;
        height: auto;
        background: #2699fb;
        position: absolute;
        height: 34px;
        left: 0;
        top: 10px;
      }
    }
  }
  form .form-item input[type="text"] {
    border-radius: 0;
    border-top: none;
    border-right: 0;
    border-left: 0;
    font: normal normal normal 40px/50px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.2;
    &::placeholder {
      font: normal normal normal 40px/50px Roboto;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 0.2;
      @media (--small-viewport) {
        font-size: 32px;
      }
    }
  }
}

.searchForm__opened {
  div#block-custom-solut-logo-svg {
    z-index: 4;
  }
  div#block-custom-solut-logo-svg .logo-site-name {
    color: #fff;
  }
  div#block-system-main-menu {
    z-index: 4;
  }
  div#block-system-main-menu .menu li a {
    color: #fff;
    &:hover {
      color: $green-custom;
      &:before {
        color: $green-custom;
      }
    }
  }
}
