.welcomeTeaser {
  border-top: 1px dotted $dottedBorderColor;
  padding-top: 27px;
  .col-md-4 {
    &:last-child {
      padding-left: 0;
      @media (--small-viewport) {
        padding-left: 12px;
      }
    }
    @media (min-width: 768px) {
      height: 518px;
      overflow: hidden;
    }
    position: relative;
  }
  .link-read-more {
    position: absolute;
    bottom: 0px;
    font: normal normal medium 14px/16px Roboto;
  }
  &__middleCol {
    position: relative;
    padding-left: 0;
    @media (--small-viewport) {
      padding-left: 12px;
    }
    p {
      color: $blueCustom;
      margin-bottom: 13px;
      line-height: 1.1;
    }
    &:after {
      content: "";
      display: block;
      width: 93%;
      position: absolute;
      bottom: 3px;
      left: 0;
      height: 2px;
      background-color: $green;
      @media (--middle-viewport) {
        display: none;
      }
    }
  }
  p {
    @media (--small-viewport) {
      margin-bottom: 0;
    }
  }
  &__middleText {
    font: normal normal normal 40px/50px Roboto;
    color: $blueCustom;
    margin-bottom: 9px;
  }
  &__author {
    font: normal normal bold 14px/16px Roboto;
    color: $blueCustom;
    .iconSvg-user {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      svg {
        transform: translate(3px, 3px);
      }
    }
  }
  &__created {
    font: normal normal 300 12px/14px Roboto;
    color: $blueCustom;
    position: absolute;
    bottom: 32px;
    left: -5px;
    width: 100%;
    padding-left: 9px;
    .iconSvg-time {
      margin-right: 10px;
    }
    span {
      &:last-child {
        transform: translate(-4px, -5px);
        display: inline-block;
      }
    }
    @media (--middle-viewport) {
      position: static;
      margin: 20px 0;
    }
  }
  &__content {
    font: normal normal normal 14px/24px Roboto;
    color: $dottedBorderColor;
  }
}
