/* table */

.node-blog-entry,
.node-article {

  .field-name-body table {
    margin-bottom: 38px;
    thead {
      th {
        text-align: center;
        font: italic 500 15px/21px Roboto;
        letter-spacing: 0px;
        color: #1178af;
        opacity: 1;
        line-height: 27px;

        &:first-child {
          padding-left: 0;
          text-align: left;
        }

        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }

    tbody {
      tr {
        td {
          border-top: 1px dashed #3e3e3e;
          font: normal normal 400 18px/21px Roboto;
          letter-spacing: 0px;
          color: #2a2f34;
          &:first-child {
            font: normal normal 500 18px/21px Roboto;
          }
        }
        &:first-child td {
          border-top: 1px solid #3e3e3e;
        }
      }
    }
  }
  .field-name-body table.complicated {

    thead {
      border-top: 1px solid #000;
    }
    td, th {
      vertical-align: middle;
    }
    th {
      background: #eef2f4;
      font: normal normal bold 16px/21px Roboto;
      letter-spacing: 0px;
      color: #2a2f34;
      vertical-align: middle;
      padding: 28px 23px;
      text-align: center;
      border-right: 1px solid #000;
      border-top: 1px dashed #000;
      &:last-child {
        padding: 0;
        text-align: center;
        border-right: 0;
      }
      &:first-child {
        border-right: 1px solid #000;
      }
    }
    tr:first-child th {
      border-top: 1px solid #000;
    }
    td:last-child {
      text-align: center;
    }
    [rowspan] {
      border-left: 1px solid #000;
      &:first-child {
        border-left: 0;
      }
    }

  }

  @media (max-width: 767px) {
    .field-name-body .table-responsive {
      tr,
      tr:first-child,
      tr:last-child {
        margin-bottom: 30px;
        td {
          text-align: center;
          border: 1px solid black;
          border-bottom: 0 ;
          &:before {
            content: none;
          }
          &:last-child {
            text-align: center;
            border-bottom: 1px solid black ;
          }
          &:first-child {
            width: auto;
          }
        }
      }
    }
  }
}